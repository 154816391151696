import React from 'react';
import { Form, Input, Button,Select,Spin,Result,message,Card,InputNumber } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';


var sacco= ''
var token= ''


class LoanApplicationForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    loan_type:'',
    account:'',
    savings_balance:0,
    loan_balance:0,
    loan_amount:0,
    loan_purpose:'',
    business_type:'',
    applicant_location:'',
    own_premises:'',
    legal_status_of_business:'',
    duration_in_business:'',
    collateral_one:'',
    collateral_one_value:0,
    collateral_two:'',
    collateral_two_value:0,
    loantypes:[],
    members:[],
    loantypeobj:{},
    accountobj:{},
    datasubmittedsuccessfully:false,
    datarequested:true,
    saccopackageobj:{},
    members:[],
    saccoboject:{},
    loanapplications:[]
  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }


      axios.get(serverconfig.backendserverurl+`/api/loantypes/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            loantypes:res.data
          })
      })
  
      axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            members:res.data
          })
  
      })

      axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            loanapplications:res.data
          })
  
      })


      axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
      .then(res => {
          this.setState({
            saccoboject:res.data
          })
  
          //get the sacco package here
          axios.get(serverconfig.backendserverurl+`/api/packages/${res.data.package}`)
          .then(res => {
              this.setState({
                saccopackageobj:res.data
              })
  
              this.setState({datarequested:false})
  
          })
  
      })

  }

  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('loan_type', this.state.loan_type);
    form_data.append('account', this.state.account);
    form_data.append('savings_balance', this.state.savings_balance);
    form_data.append('loan_balance', this.state.loan_balance);
    form_data.append('loan_amount', this.state.loan_amount);
    form_data.append('loan_purpose', this.state.loan_purpose);
    form_data.append('business_type', this.state.business_type);
    form_data.append('applicant_location', this.state.applicant_location);
    form_data.append('own_premises', this.state.own_premises);
    form_data.append('legal_status_of_business', this.state.legal_status_of_business);
    form_data.append('duration_in_business', this.state.duration_in_business);
    form_data.append('collateral_one', this.state.collateral_one);
    form_data.append('collateral_one_value', this.state.collateral_one_value);
    form_data.append('collateral_two', this.state.collateral_one);
    form_data.append('collateral_two_value', this.state.collateral_one_value);
    form_data.append('sacco', sacco);
    
    axios.post(serverconfig.backendserverurl+'/api/loanapplications/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>         
    this.setState({datarequested:false})
,  this.setState({datasubmittedsuccessfully:true})
  )
  .catch(error => console.log(error))

  }

  handleloan_typeChange= (e) => {
    this.setState({ loan_type: e});
    //get loan type details
    //get account details
    axios.get(`${serverconfig.backendserverurl}/api/loantypes/${e}`)
      .then(res => {  
          this.setState({loantypeobj: res.data})
      })
  }


  handleaccountChange= (e) =>{
    this.setState({ account: e});
    //get account details
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({accountobj: res.data})
          this.setState({savings_balance:res.data.UserBalance})
      })

      //check the last loan attributes
      let form_data = new FormData();
      form_data.append('account_id', e);

      axios.post(serverconfig.backendserverurl+'/customqueries/getlastloanattributes', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
          this.setState({loan_balance:res.data.user_info.totalLoanBalance})
        
      })
      .catch(error => console.log(error))

  } 
  
  
  
  handlesavings_balanceChange= (e) => this.setState({ savings_balance: e.target.value});
  handleloan_balanceChange= (e) => this.setState({ loan_balance: e.target.value});
  handleloan_amountChange= (e) => this.setState({ loan_amount: e});
  handleloan_purposeChange= (e) => this.setState({ loan_purpose: e.target.value});
  handlebusiness_typeChange= (e) => this.setState({ business_type: e.target.value});
  handleapplicant_locationChange= (e) => this.setState({ applicant_location: e.target.value});
  handleown_premisesChange= (e) => this.setState({ own_premises: e});
  handlelegal_status_of_businessChange= (e) => this.setState({ legal_status_of_business: e});
  handleduration_in_businessChange= (e) => this.setState({ duration_in_business: e.target.value});
  handlecollateral_oneChange= (e) => this.setState({ collateral_one: e.target.value});
  handlecollateral_one_valueChange= (e) => this.setState({ collateral_one_value: e.target.value});
  handlecollateral_twoChange= (e) => this.setState({ collateral_two: e.target.value});
  handlecollateral_two_valueChange= (e) => this.setState({ collateral_two_value: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <Result
            status="success"
            title="Successfully Submitted loan Application "
            subTitle="Application will be ready for asessment"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />

            </Card>
            </Col>
            </Row>
        );

      }else{

        if(this.state.loanapplications.length>=this.state.saccopackageobj.loanslimit){
          return (
            <div>
              <h4 style={{color:'red'}}>You have reached loans limit for this package.Please contact adminstrators for upgrade of package.Thanx</h4>
            </div>
          );

        }else{


          return (
            <div>
                <Row >
                      <Col xs="12" sm="6" lg="6">
                        <Card>
                        <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN APPLICATION FORM</h4>
                        <Form
                          onFinish={(event) => this.handleFormSubmit(event)} 
                        >
    
                        <FormItem label="Loan Type"
                            name="loantype"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Type',
                              },
                            ]}           
                          >
                              <Select 
                              placeholder="Loan Type" 
                              style={{  }} 
                              value={this.state.loan_type} 
                              onChange={this.handleloan_typeChange} 
                              showSearch
                              optionFilterProp="children"
                              onFocus={this.onFocus}
                              onBlur={this.onBlur}
                              onSearch={this.onSearch}
                              
                              >
                                {this.state.loantypes.map(
                                  (stf)=>(
                                    <Option value={stf.id}>{stf.loan_type_name}</Option>
                                  ))}
                              </Select>
                          </FormItem>
    
    
                          <FormItem label="Account"
                            name="account"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Account',
                              },
                            ]}           
                          >
                              <Select 
                              placeholder="Account" 
                              style={{  }} value={this.state.account} 
                              onChange={this.handleaccountChange} 
                              showSearch
                              optionFilterProp="children"
                              onFocus={this.onFocus}
                              onBlur={this.onBlur}
                              onSearch={this.onSearch}
                              >
                                {this.state.members.map(
                                  (stf)=>(
                                    <Option value={stf.id}>{stf.AccountNumber} | {stf.FullName} </Option>
                                  ))}
                              </Select>
                          </FormItem>
    
    
    
    
                          <FormItem label="Savings Balance"             
                          >
                            <Input name="savings_balance"  type='number' placeholder="Savings Balance." value={this.state.savings_balance} onChange={this.handlesavings_balanceChange} />
                          </FormItem>
    
    
                          <FormItem label="Loan Balance"
           
                          >
                            <Input name="loan_balance" type='number' placeholder="0.00" value={this.state.loan_balance} onChange={this.handleloan_balanceChange} />
                          </FormItem>
                
    
                          <FormItem label="Loan Request Amount"
                            name="loan_amount"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter values',
                              },
                            ]}           
                          >
    
                          <InputNumber
                            style={{ width: 200 }} 
                            defaultValue={0.0}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\x\s?|(,*)/g, '')}
                            onChange={this.handleloan_amountChange}
                          />
    
                          </FormItem>
    
                
                          <FormItem label="Loan Purpose"
                            name="loan_purpose"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter purpose',
                              },
                            ]}           
                          >
                            <Input name="loan_purpose"  placeholder="Purpose" value={this.state.loan_purpose} onChange={this.handleloan_purposeChange} />
                          </FormItem>
    
                          <FormItem label="Business Type"
                            name="business_type"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter type',
                              },
                            ]}           
                          >
                            <Input name="business_type"  placeholder="Type" value={this.state.business_type} onChange={this.handlebusiness_typeChange} />
                          </FormItem>
    
    
    
                          <FormItem label="Applicant Location"
                            name="applicant_location"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter location',
                              },
                            ]}           
                          >
                            <Input name="applicant_location"  placeholder="Location" value={this.state.applicant_location} onChange={this.handleapplicant_locationChange} />
                          </FormItem>
    
                          <FormItem label="Own Premises?"
                            name="own_premises"
                            rules={[
                              {
                                required: true,
                                message: 'Please select',
                              },
                            ]}           
                          
                          >
                              <Select placeholder="Own Premises" style={{ width: 120 }} value={this.state.own_premises} onChange={this.handleown_premisesChange} >
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                              </Select>
                          </FormItem>
    
                          <FormItem label="Legal Status Of Business"
                            name="legal_status_of_business"
                            rules={[
                              {
                                required: true,
                                message: 'Please select',
                              },
                            ]}           
                          
                          >
                              <Select placeholder="Legal Status" style={{ width: 120 }} value={this.state.legal_status_of_business} onChange={this.handlelegal_status_of_businessChange} >
                                    <Option value="Registered">Registered</Option>
                                    <Option value="UnRegistered">UnRegistered</Option>
                              </Select>
                          </FormItem>
    
                          <FormItem label="Duration In Business"
                            name="duration_in_business"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter duration',
                              },
                            ]}           
                          >
                            <Input name="duration_in_business"  placeholder="Location" value={this.state.duration_in_business} onChange={this.handleduration_in_businessChange} />
                          </FormItem>
    
    
                          <FormItem label="Collateral One"
                            name="collateral_one"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter ',
                              },
                            ]}           
                          >
                            <Input name="collateral_one"  placeholder="0" value={this.state.collateral_one} onChange={this.handlecollateral_oneChange} />
                          </FormItem>
    
    
    
                          <FormItem label="Collateral One Value"
                            name="collateral_one_value"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter values',
                              },
                            ]}           
                          >
                            <Input name="collateral_one_value" type='number'  placeholder="0.00" value={this.state.collateral_one_value} onChange={this.handlecollateral_one_valueChange} />
                          </FormItem>
    
    
                          <FormItem label="Collateral Two"
                            name="collateral_two"
                            rules={[
                              {
                                required: false,
                                message: 'Please enter ',
                              },
                            ]}           
                          >
                            <Input name="collateral_two"  placeholder="0" value={this.state.collateral_two} onChange={this.handlecollateral_twoChange} />
                          </FormItem>
    
    
    
                          <FormItem label="Collateral Two Value"
                            name="collateral_two_value"
                            rules={[
                              {
                                required: false,
                                message: 'Please enter values',
                              },
                            ]}           
                          >
                            <Input name="collateral_two_value" type='number'  placeholder="0.00" value={this.state.collateral_two_value} onChange={this.handlecollateral_two_valueChange} />
                          </FormItem>
                          
    
                          <FormItem>
                            <Button  type="primary" htmlType="submit">Create</Button>
                          </FormItem>
                        </Form>
    
    
                        </Card>
                        </Col>
    
    
                        <Col xs="12" sm="6" lg="6">
                        <Card>
                        <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Loan Type Details</h4>
                        <h6>Loan Type Name: {this.state.loantypeobj.loan_type_name}</h6>
                        <h6>Interest Rate: {this.state.loantypeobj.interest_rate}</h6>
                        <h6>Assessment Fee: {this.state.loantypeobj.loan_assessment_fees}</h6>
                        <h6>Insurance Fee: {this.state.loantypeobj.loan_insurance_fee}</h6>
                        <h6>Commitment Fee: {this.state.loantypeobj.loan_commitment_fee}</h6>
                        <h6>Form Fee: {this.state.loantypeobj.loan_form_fees}</h6>
                        <h6>Maximum Loan Amount: {this.state.loantypeobj.maximum_loan_amount}</h6>
                        <h6>Maximum Period: {this.state.loantypeobj.maximum_period}</h6>
                        <br></br>
                        <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Account Details</h4>
                        <h6>Account Name: {this.state.accountobj.FullName}</h6>
                        <h6>Account Number: {this.state.accountobj.AccountNumber}</h6>
                        <h6>Savings Balance: {this.state.accountobj.UserBalance}</h6>
                        </Card>
                        </Col>
                </Row>
    
            </div>
          );

        }









      }

     
    }


    
  }

}


export default LoanApplicationForm;



