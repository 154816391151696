import React from 'react';
import { Form, Input, Button,Select,InputNumber,DatePicker,message,Card,Spin,Result } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var sacco= ''
var token= ''



class AccountOpeningItemForm extends React.Component {

  state = {
    accounttypeid:'',  
    activationmode:'',
    datarequested:false,
    datasubmittedsuccessfully:false,
    itemname:'',
    itemprice:0
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    const accounttypeid=this.props.accounttypeid;
    this.setState({accounttypeid:accounttypeid})
  }


  //submit button pressed
  onFinish=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('item_name', this.state.itemname);
    form_data.append('item_price', this.state.itemprice);
    form_data.append('accountType', this.state.accounttypeid);
    form_data.append('sacco', sacco);

    ///make a post request now
      axios.post(serverconfig.backendserverurl+'/api/accountopeningitems/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        if (res.data){
          //message.info(res.data.message)
          this.setState({datarequested:false})
          this.setState({datasubmittedsuccessfully:true})
        }
      })
      .catch(error => console.log(error))

    }

    handleitemnameChange= (e) => this.setState({ itemname: e.target.value});
    handleitempriceChange= (e) => this.setState({ itemprice: e.target.value});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Created Account Opening Item "
            subTitle="Account opening items are usually deducted on account opening"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

        return (
          <div>
            <Card>
    
            <Form
                  name="activationform"
                  onFinish={(event) => this.onFinish(event)}
              >
    
    
                <FormItem 
                      label="Item Name"
                      name="itemname"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter name',
                        },
                      ]}
                      >
              <Input name="itemname"  placeholder="Put a name here." value={this.state.itemname} onChange={this.handleitemnameChange} />                          
              </FormItem>
    
              <FormItem 
                      label="Item Price"
                      name="itemprice"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter name',
                        },
                      ]}
                      >
              <Input name="itemprice"  type='number' placeholder="Put price here." value={this.state.itemprice} onChange={this.handleitempriceChange} />                          
              </FormItem>

                <FormItem>
                  <Button  type="primary" htmlType="submit">Submit</Button>
                </FormItem>
                </Form>
            </Card>
    
     
          </div>
        );

      }






      

    }

    
  }

}


export default AccountOpeningItemForm;



