import React from 'react';
import axios from 'axios';
import { Card, Button,Input,message,Form,Spin} from 'antd';
import { Row, Col } from 'antd';
import StaffRoleForm from '../components/StaffRoleForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'

const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class StaffRoleDetail extends React.Component{

    state={
        staffrole: {},
        staffroleName:'' ,  
        staffroleDescription:'',
        datarequested:true,
    }

    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }


        const staffroleID=this.props.match.params.staffroleID;
        axios.get(`${serverconfig.backendserverurl}/api/staffroles/${staffroleID}`)
        .then(res => {  
            this.setState({
                staffrole: res.data
            })
            this.setState({staffroleName: res.data.role_name})
            this.setState({staffroleDescription: res.data.description})

            this.setState({datarequested:false})

        })

    }


    handlestaffroleNameChange= (e) => this.setState({ staffroleName: e.target.value});
    handleIDDescriptionChange= (e) => this.setState({ staffroleDescription: e.target.value});
  
    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})
    const staffroleID=this.props.match.params.staffroleID;

    let form_data = new FormData();
    form_data.append('role_name', this.state.staffroleName);
    form_data.append('description', this.state.staffroleDescription);

    axios.put(`${serverconfig.backendserverurl}/api/staffroles/${staffroleID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res => this.setState({datarequested:false}),window.location.reload(false),message.info('Successfully updated'))
    .catch(error => console.log(error))

  }

    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{

            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card title={this.state.staffrole.role_name}>
                        <p>Desc: {this.state.staffrole.description}</p>
                    </Card>
                    </Col>
                    </Row>
                    <br/>
    
                    <Row>
    
                    <Col xs="12" sm="6" lg="4">
                    <Card 
                    style={{padding:10}}
                    hoverable>
                        <h4>Staff Role Update</h4>
                        <Form>
                        <FormItem label="Staff Role Name">
                        <Input name="staffrolename"  placeholder="Put a role name here." value={this.state.staffroleName} onChange={this.handlestaffroleNameChange} />
                        </FormItem>
                        <FormItem label="Description">
                        <Input name="description"  placeholder="Description here" value={this.state.staffroleDescription} onChange={this.handleIDDescriptionChange} />
                        </FormItem>
                    
                        <FormItem>
                        <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                        </FormItem>
                     </Form>
    
                    </Card>
    
    
                    </Col>
    
                 
                    </Row>
    
                    <br/>
    
     
                </div>
            )
          }
        
    }
}

export default StaffRoleDetail; 