import React from 'react';
import axios from 'axios';
import { Card,Avatar} from 'antd';
import { Form, Input, Button,Select,Spin } from 'antd';
import {
    Col,
    Row,
  } from 'reactstrap';
import AssetForm from '../components/AssetForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class AssetDetail extends React.Component{

    state={
        asset: {},
        assetcategories:[],
        assetname:'' ,  
        assetcategory:'',
        netbookvalue:0,
        assetphoto:null,
        details:'',
        datarequested:true,

    }


    componentDidMount(){

      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }
      
        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }

        const assetID=this.props.match.params.assetID;
        axios.get(`${serverconfig.backendserverurl}/api/assets/${assetID}`)
        .then(res => {  
            this.setState({asset: res.data})

            this.setState({assetname: res.data.asset_name})
            this.setState({assetcategory: res.data.asset_category})
            this.setState({netbookvalue: res.data.net_book_value})
            this.setState({details: res.data.details})

            this.setState({datarequested:false})

        })
    }

    //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    const assetID=this.props.match.params.assetID;

    let form_data = new FormData();
    form_data.append('asset_name', this.state.assetname);
    form_data.append('asset_category', this.state.assetcategory);
    form_data.append('net_book_value', this.state.netbookvalue);
    form_data.append('details', this.state.details);

    this.state.assetphoto==null?
    console.log("No asset file")
    :
    form_data.append('asset_photo', this.state.assetphoto,this.state.assetphoto?this.state.assetphoto.name:"");

    axios.put(serverconfig.backendserverurl+`/api/assets/${assetID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>this.setState({datarequested:false}),window.location.reload(false)

  )
  .catch(error => console.log(error))

  }


    handleassetnameChange= (e) => this.setState({ assetname: e.target.value});
    handleassetcategoryChange= (e) => this.setState({ assetcategory: e});
    handlenetbookvalueChange= (e) => this.setState({ netbookvalue: e.target.value});
    handleassetphotoChange= (e) => this.setState({ assetphoto: e.target.files[0]});
    handledetailsChange= (e) => this.setState({ details: e.target.value});

    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{

            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card title={this.state.asset.asset_name}>
                        <p>Category: {this.state.asset.categoryname}</p>
                        <p>Net Book Value: {this.state.asset.net_book_value}</p>
                        <p>Details: {this.state.asset.details}</p>
                    </Card>
                    </Col>
                  
                    <Col xs="12" sm="6" lg="4">
                    <Card 
                      style={{padding:10}}
                      hoverable>
                    <h4>Asset Image</h4>
                    <Avatar
                        size={150}
                        icon={<UserOutlined />}
                        alt="Company Logo"
                        src={this.state.asset.asset_photo}
                    />
                          
                    </Card>
    
                   
                    </Col>
    
                    </Row>
    
                    <Row>
                    <Col xs="12" sm="6" lg="4">
    
                    <Card 
                      style={{padding:10}}
                      hoverable>
                        <h4>Asset Update</h4>
                        <Form
                            onFinish={(event) => this.handleFormSubmit(event)} 
                            >
                            <FormItem label="Asset Name"
                                          
                            >
                                <Input name="assetname"  placeholder="Put a name here." value={this.state.assetname} onChange={this.handleassetnameChange} />
                            </FormItem>
    
                            <FormItem label="Asset Category"
                                    
                            >
                                <Select  name="assetcategory" placeholder="Asset Category" style={{ width: 120 }} value={this.state.assetcategory} onChange={this.handleassetcategoryChange} >
                                    {this.state.assetcategories.map(
                                    (categry)=>(
                                        <Option  value={categry.id}>{categry.category_name}</Option>
                                    ))}
                                </Select>
                            </FormItem>
    
                            <FormItem label="Net Book Value"
                                   
                            >
                                <Input name="netbookvalue"  placeholder="0.00" value={this.state.netbookvalue} onChange={this.handlenetbookvalueChange} />
                            </FormItem>
    
                            <FormItem label="Asset Photo">
                                <Input name="assetphoto" type="file" accept="image/png, image/jpeg" placeholder="Asset Photo" onChange={this.handleassetphotoChange} />
                            </FormItem>
    
                            <FormItem label="Asset Details"
                                         
                            >
                                <Input name="assetdetails"  placeholder="Details." value={this.state.details} onChange={this.handledetailsChange} />
                            </FormItem>
    
                            <FormItem>
                                <Button  type="primary" htmlType="submit">Update</Button>
                            </FormItem>
                            </Form>
                          
                          </Card>
                      
                    </Col>
                  
                   </Row>
    
                
                </div>
            )

          }

        
    }
}

export default AssetDetail; 