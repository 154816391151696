import React from 'react';
import axios from 'axios';
import { Card, Button,Form, Input,Select,Spin} from 'antd';
import {
    Col,
    Row,
  } from 'reactstrap';
import AssetCategoryForm from '../components/AssetCategoryForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class AssetCategoryDetail extends React.Component{

    state={
        assetcategory: {},
        categoryname:'' ,  
        categorytype:'Depreciation',
        percentagevalue:'',
        description:'',
        datarequested:true,
    }

    componentDidMount(){
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

        const assetcategoryID=this.props.match.params.assetcategoryID;
        axios.get(`${serverconfig.backendserverurl}/api/assetcategories/${assetcategoryID}`)
        .then(res => {  
            this.setState({assetcategory: res.data})

            this.setState({categoryname: res.data.category_name})
            this.setState({categorytype: res.data.category_type})
            this.setState({percentagevalue: res.data.percentage_value})
            this.setState({description: res.data.description})

            this.setState({datarequested:false})

        })
    }

      //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    const assetcategoryID=this.props.match.params.assetcategoryID;

    let form_data = new FormData();
    form_data.append('category_name', this.state.categoryname);
    form_data.append('category_type', this.state.categorytype);
    form_data.append('percentage_value', this.state.percentagevalue);
    form_data.append('description', this.state.description);

      axios.put(serverconfig.backendserverurl+`/api/assetcategories/${assetcategoryID}/`, form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => this.setState({datarequested:false}), window.location.reload(false)

    )
    .catch(error => console.log(error))

  }

  handlecategorynameChange= (e) => this.setState({ categoryname: e.target.value});
  handlecategorytypeChange= (e) => this.setState({ categorytype: e});
  handlepercentagevalueChange= (e) => this.setState({ percentagevalue: e.target.value});
  handledescriptionChange= (e) => this.setState({ description: e.target.value});


    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card title={this.state.assetcategory.category_name}>
                        <p>Category Type: {this.state.assetcategory.category_type}</p>
                        <p>Percentage Value: {this.state.assetcategory.percentage_value}</p>
                        <p>Desc: {this.state.assetcategory.description}</p>
                    </Card>
                    </Col>
                    <Col xs="12" sm="6" lg="4">
                    <Card title={"Update Here"}>
                    <Form
                        onFinish={(event) => this.handleFormSubmit(event)} 
                    >
                    <FormItem label="Category Name"
                       
                    >
                        <Input  placeholder="Put a name here." value={this.state.categoryname} onChange={this.handlecategorynameChange} />
                    </FormItem>
                    <FormItem label="Category Type"
                    >
                        <Select  name="categorytype"  style={{ width: 120 }} value={this.state.categorytype} onChange={this.handlecategorytypeChange} >
                            <Option  value="Depreciation">Depreciation</Option>
                            <Option  value="Appreciation">Appreciation</Option>
                        </Select>
                    </FormItem>
    
                    <FormItem label="Percentage"
                    >
                        <Input name="percentagevalue"  placeholder="0.00" value={this.state.percentagevalue} onChange={this.handlepercentagevalueChange} />
                    </FormItem>
    
                    <FormItem label="Description"          
                    >
                        <Input name="description"  placeholder="Description here" value={this.state.description} onChange={this.handledescriptionChange} />
                    </FormItem>
                    
                    <FormItem>
                        <Button  type="primary" htmlType="submit">Update</Button>
                    </FormItem>
                    </Form>
                    </Card>
    
                    </Col>
    
                    </Row>
                    <br/>
    
                </div>
            )


          }




       
    }
}

export default AssetCategoryDetail; 