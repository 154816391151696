import React from 'react';
import { Form, Input, Button,Select,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var sacco= ''
var token= ''


class AssetCategoryForm extends React.Component {

  state = {
    categoryname:'' ,  
    categorytype:'Depreciation',
    percentagevalue:'',
    description:'',
    datarequested:false,
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('category_name', this.state.categoryname);
    form_data.append('category_type', this.state.categorytype);
    form_data.append('percentage_value', this.state.percentagevalue);
    form_data.append('description', this.state.description);
    form_data.append('sacco', sacco);
    
      axios.post(serverconfig.backendserverurl+'/api/assetcategories/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>    this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }

  handlecategorynameChange= (e) => this.setState({ categoryname: e.target.value});
  handlecategorytypeChange= (e) => this.setState({ categorytype: e});
  handlepercentagevalueChange= (e) => this.setState({ percentagevalue: e.target.value});
  handledescriptionChange= (e) => this.setState({ description: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
          <Form
               onFinish={(event) => this.handleFormSubmit(event)} 
          >
            <FormItem label="Category Name"
              name="categoryname"
              rules={[
                {
                  required: true,
                  message: 'Please input name',
                },
              ]}
            >
              <Input name="categoryname"  placeholder="Put a name here." value={this.state.categoryname} onChange={this.handlecategorynameChange} />
            </FormItem>
            <FormItem label="Category Type"
              name="categorytype"
              rules={[
                {
                  required: true,
                  message: 'Please select category',
                },
              ]}          
            
            >
                <Select  name="categorytype"  style={{ width: 120 }} value={this.state.categorytype} onChange={this.handlecategorytypeChange} >
                  <Option  value="Depreciation">Depreciation</Option>
                  <Option  value="Appreciation">Appreciation</Option>
                </Select>
            </FormItem>
  
            <FormItem label="Percentage"
              name="percentage"
              rules={[
                {
                  required: true,
                  message: 'Please select category',
                },
              ]}           
            >
              <Input name="percentagevalue"  placeholder="0.00" value={this.state.percentagevalue} onChange={this.handlepercentagevalueChange} />
            </FormItem>
  
            <FormItem label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please input description',
                },
              ]}           
            >
              <Input name="description"  placeholder="Description here" value={this.state.description} onChange={this.handledescriptionChange} />
            </FormItem>
          
            <FormItem>
              <Button  type="primary" htmlType="submit">Create</Button>
            </FormItem>
          </Form>
        </div>
      );


    }
    
  }

}


export default AssetCategoryForm;



