import React from 'react';
import { Form, Input, Button,Select,Spin,Result,message,Card,Switch,InputNumber } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;


var sacco= ''
var username=''
var token= ''

class LoanAppovalForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    approved_amount:0,
    loan_pass:false,
    approval_report:'',
    loanapplication:{},
    loanassessment:{},
    datasubmittedsuccessfully:false,
    datarequested:true,
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    const loanapplicationID=this.props.match.params.loanapplicationID;

    axios.get(`${serverconfig.backendserverurl}/api/loanapplications/${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapplication: res.data})
        this.setState({approved_amount:res.data.loan_amount})
    })


    axios.get(`${serverconfig.backendserverurl}/api/loanassessments/?loan_assessed=${loanapplicationID}`)
    .then(res => {  
        this.setState({loanassessment: res.data[0]})
        this.setState({datarequested:false})
    })

  }

  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    const loanapplicationID=this.props.match.params.loanapplicationID;

    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('loan_id', loanapplicationID);
    form_data.append('approved_amount', this.state.approved_amount);
    form_data.append('approval_report', this.state.approval_report);
    form_data.append('loan_pass', this.state.loan_pass);
    form_data.append('user',username );

    form_data.append('sacco', sacco);
    
    axios.post(serverconfig.backendserverurl+'/api/loanapprovals/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>  {
    var obj=res.data
    console.log(Object.keys(obj).length)
    if(Object.keys(obj).length>0){

      let form_data = new FormData();
      form_data.append('looanid', loanapplicationID);

      if(this.state.loan_pass===true){
        form_data.append('loanpassed', 'true');
      }else{
        form_data.append('loanpassed', 'false');
      }

      axios.post(serverconfig.backendserverurl+'/customqueries/updateloanappovalstatus', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
          message.info(res.data.message)
          this.setState({datarequested:false})
          this.setState({datasubmittedsuccessfully:true})
      })
      .catch(error => console.log(error))

    }else{
      message.info("Some thing went wrong")
    }


  }       

  )
  .catch(error => console.log(error))

  }

 
  
  handleapproved_amountChange= (e) => this.setState({ approved_amount: e.target.value});
  handleloan_passChange= (e) => this.setState({ loan_pass: e});
  handleapproval_reportChange= (e) => this.setState({ approval_report: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <Result
            status="success"
            title="Successfully Done loan approval "
            subTitle="If the loan is passed it will be forwarded for issuance or disburshment"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />

            </Card>
            </Col>
            </Row>
        );

      }else{
        return (
          <div>
              <Row >
                    <Col xs="12" sm="6" lg="6">
                      <Card>
                      <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN APPROVAL FORM</h4>
                     
                     {
                       this.state.loanapplication.approval_yet?
                       null:
                       <Form
                       onFinish={(event) => this.handleFormSubmit(event)} 
                     >

                    <FormItem label="Approved Amount">
                         <Input name="approved_amount" type='number'  placeholder="Approval Amount." value={this.state.approved_amount} onChange={this.handleapproved_amountChange} />
                       </FormItem>

 
                     <FormItem label="Report Details"
                             name="approval_report"
                             rules={[
                               {
                                 required: true,
                                 message: 'Please enter details',
                               },
                             ]}            
                           >
                             <TextArea
                               value={this.state.approval_report}
                               onChange={this.handleapproval_reportChange}
                               placeholder="Report Details"
                               autoSize={{ minRows: 2, maxRows: 6 }}
                             />
                         
                       </FormItem>

                       <FormItem label="Pass Loan?" >
                           <Switch
                               checked={this.state.loan_pass}
                               onChange={val=>{this.handleloan_passChange(val)}}
                               checkedChildren="True"
                               unCheckedChildren="False"
                               />
                       </FormItem>


                       
                       <FormItem>
                         <Button  type="primary" htmlType="submit">Submit</Button>
                       </FormItem>
                     </Form>
                     }
                     
                    
  
  
                      </Card>
                      </Col>
  
  
                      <Col xs="12" sm="6" lg="6">
                      <Card>
                      <h4 style={{fontStyle:'oblique',fontWeight:'bolder',color:'green'}}>Loan Assessment Details</h4>
                      <h6 style={{fontWeight:'bold'}}>Applicant Location: {this.state.loanassessment.applicant_location?"True":"False"}</h6>
                      <h6 style={{fontWeight:'bold'}}>Applicant Own Premises: {this.state.loanassessment.own_premises?"True":"False"}</h6>
                      <h6 style={{fontWeight:'bold'}}>Legal Status Of Business: {this.state.loanassessment.legal_status_of_business?"True":"False"}</h6>
                      <h6 style={{fontWeight:'bold'}}>Duration In Business: {this.state.loanassessment.duration_in_business?"True":"False"}</h6>
                      <h6 style={{fontWeight:'bold'}}>Monthly Net Profit Of Business: {this.state.loanassessment.monthly_net_profit?"True":"False"}</h6>
                      <h6 style={{fontWeight:'bold'}}>Guarantors available? {this.state.loanassessment.guarantors?"True":"False"}</h6>
                      <h6 style={{fontWeight:'bold'}}>Conscents available? {this.state.loanassessment.consents?"True":"False"}</h6>
                     
                      <h6 style={{fontWeight:'bold'}}>Collateral Value {this.state.loanassessment.collateral_one_value} UGX</h6>

                      {
                       this.state.loanassessment.collateral_one_image===null?
                       null:
                       <h6 style={{fontWeight:'bold'}}>Collateral Image: <a href={this.state.loanassessment.collateral_one_image}><Button>DownLoad Collateral Image</Button></a></h6>
                       }

                     {
                       this.state.loanassessment.loan_checklist===null?
                       null:
                       <h6 style={{fontWeight:'bold'}}>CheckList: <a href={this.state.loanassessment.loan_checklist}><Button>DownLoad Check List</Button></a></h6>

                     }

                    <h6 style={{fontWeight:'bold'}}>Report Details: {this.state.loanassessment.report_details}</h6>

                     
                      </Card>
                      </Col>
              </Row>
  
          </div>
        );

      }

     
    }


    
  }

}


export default LoanAppovalForm;



