import React from 'react';
import { Form, Input, Button,Select,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''

class AssetForm extends React.Component {

  state = {
    assetcategories:[],
    assetname:'' ,  
    assetcategory:'',
    netbookvalue:0,
    assetphoto:null,
    details:'',
    datarequested:true,
  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/assetcategories/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          assetcategories:res.data
        })
        this.setState({datarequested:false})

    })
}


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('asset_name', this.state.assetname);
    form_data.append('asset_category', this.state.assetcategory);
    form_data.append('net_book_value', this.state.netbookvalue);
    form_data.append('details', this.state.details);
    form_data.append('sacco', sacco);
    
    this.state.assetphoto==null?
    console.log("No asset file")
    :
    form_data.append('asset_photo', this.state.assetphoto,this.state.assetphoto?this.state.assetphoto.name:"");

    axios.post(serverconfig.backendserverurl+'/api/assets/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>         this.setState({datarequested:false})
,  window.location.reload(false)
  )
  .catch(error => console.log(error))

  }

  handleassetnameChange= (e) => this.setState({ assetname: e.target.value});
  handleassetcategoryChange= (e) => this.setState({ assetcategory: e});
  handlenetbookvalueChange= (e) => this.setState({ netbookvalue: e.target.value});
  handleassetphotoChange= (e) => this.setState({ assetphoto: e.target.files[0]});
  handledetailsChange= (e) => this.setState({ details: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
          <Form
            onFinish={(event) => this.handleFormSubmit(event)} 
          >
            <FormItem label="Asset Name"
              name="assetname"
              rules={[
                {
                  required: true,
                  message: 'Please enter asset name',
                },
              ]}             
            >
              <Input name="assetname"  placeholder="Put a name here." value={this.state.assetname} onChange={this.handleassetnameChange} />
            </FormItem>
  
            <FormItem label="Asset Category"
              name="assetcategory"
              rules={[
                {
                  required: true,
                  message: 'Please select asset category',
                },
              ]}           
            >
                <Select  name="assetcategory" placeholder="Asset Category" style={{ width: 120 }} value={this.state.assetcategory} onChange={this.handleassetcategoryChange} >
                  {this.state.assetcategories.map(
                    (categry)=>(
                      <Option  value={categry.id}>{categry.category_name}</Option>
                    ))}
                </Select>
            </FormItem>
  
            <FormItem label="Net Book Value"
              name="netbookvalues"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="netbookvalue"  placeholder="0.00" value={this.state.netbookvalue} onChange={this.handlenetbookvalueChange} />
            </FormItem>
  
            <FormItem label="Asset Photo">
              <Input name="assetphoto" type="file" accept="image/png, image/jpeg" placeholder="Asset Photo" onChange={this.handleassetphotoChange} />
            </FormItem>
  
            <FormItem label="Asset Details"
              name="assetdetails"
              rules={[
                {
                  required: true,
                  message: 'Please enter details',
                },
              ]}            
            >
              <Input name="assetdetails"  placeholder="Details." value={this.state.details} onChange={this.handledetailsChange} />
            </FormItem>
  
            <FormItem>
              <Button  type="primary" htmlType="submit">Create</Button>
            </FormItem>
          </Form>
        </div>
      );


    }


    
  }

}


export default AssetForm;



