import React from 'react';
import LoanApplicationForm from '../components/LoanApplicationForm'
import GuarantorConsentForm from '../components/GuarantorConsentForm'
import MdiGuarantorForm from '../components/MdiGuarantorForm'

import axios from 'axios'
import { Table, Input,Dropdown,Menu,Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,LoadingOutlined,PlusCircleFilled } from '@ant-design/icons';
import { Link, NavLink } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import ExpensePaymentForm from '../components/ExpensePaymentForm'
var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

var sacco= ''
var bizuserid= ''
var token= ''

class LoanApplicationsList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    loanapplications: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    guarantorconsentformvisible:false,
    selectedloanid:'',
    nextaction:'',
    mdiguarantorformvisible:false,
    userrights:{},

  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }
    

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}&assessed=${false}&passed=${false}&issued=${false}&approval_yet=${false}`)
    .then(res => {
        this.setState({
          loanapplications:res.data
        })


    })

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
        this.setState({datarequested:false})
    })

}

guarantorsmenu =(val)=>{
  return(
    <Menu >
    <Menu.Item key="1" onClick={()=>{this.openmdiguarantorform(val)}}>
      <PlusCircleFilled style={{color:'blue'}}/> Sacco Guarantor
    </Menu.Item>
    <Menu.Item key="2">
      <NavLink to={`/loanguarantors/${val}`} >
      <FundViewOutlined style={{color:'blue'}}/> View Guarantors
      </NavLink>
    </Menu.Item>
    <Menu.Item key="4">
      <NavLink to={`/loanconsents/${val}`} >
      <FundViewOutlined style={{color:'blue'}}/> View Consents
      </NavLink>
    </Menu.Item>
  </Menu>
  );
} 


    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/loanapplications/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
   
  };

//open guarantor form
  openguarantorform=id=>{
    this.setState({selectedloanid:id})
    this.setState({guarantorconsentformvisible:true})
    this.setState({nextaction:'addguarantor'})
  }

  //open consent form
  openconsentform=id=>{
    this.setState({selectedloanid:id})
    this.setState({guarantorconsentformvisible:true})
    this.setState({nextaction:'addconsent'})
  }  

  closeguatantorconsentform=()=>{
    this.setState({guarantorconsentformvisible:false})
  }

  //open mdi guarantor form
  openmdiguarantorform=(val)=>{
    this.setState({selectedloanid:val})
    this.setState({mdiguarantorformvisible:true})
  }

  closemdiguarantorform=()=>{
    this.setState({mdiguarantorformvisible:false})
  }



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

      var columns = [

        {
          title: 'Account Number',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),
        },
        {
          title: 'Request Amount',
          dataIndex: 'loan_amount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Collateral',
          dataIndex: 'collateral_one',
          key: 'id',
        },
        {
          title: 'Collateral Value',
          dataIndex: 'collateral_one_value',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/loanapplications/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>

          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to Assess Loan</p>} title="Assess">
            <Link to={`/makeassessment/${text}`}><FundViewOutlined style={{color:'green'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to add guarantor</p>} title="Add Guarantor">
           <PlusCircleFilled style={{color:'blue'}} onClick={()=>{this.openguarantorform(text)}}/>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to add consent</p>} title="Add Consent">
           <PlusCircleFilled style={{color:'sandybrown'}} onClick={()=>{this.openconsentform(text)}}/>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>

          &nbsp;&nbsp;&nbsp;
          <Dropdown.Button  onClick={()=>{this.setState({selectedloanid:text})}}  overlay={this.guarantorsmenu(text)}>
            More
          </Dropdown.Button>

          </p>
          ,
        }
  
      ];
    
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>AVAILABLE LOAN APPLICATIONS</h4>} key="1">
            <Table   
            columns={columns} 
            scroll={{ x: 1000 }}
            dataSource={this.state.loanapplications} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered/>
            <br />
            </Panel>
            </Collapse>
            <br></br>
            {
              this.state.userrights.can_register_loans?
              <Collapse defaultActiveKey={['0']} onChange={callback}>
              <Panel header="Collapse Panel to open Form" key="1">
                <h2>Create Application here</h2>
                <LoanApplicationForm 
                requestType="post"
                expenseID={null}
                btnText="Create"
                /> 
              </Panel>
              </Collapse>
              :
              null
            }

            <Modal              
              visible={this.state.guarantorconsentformvisible}
              title={this.state.nextaction==="addguarantor"? "Guarantor Form":"Consent Form"}
              onCancel={this.closeguatantorconsentform}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={this.closeguatantorconsentform}>
                  Cancel
                </Button>
                ]}
                >
                <GuarantorConsentForm action={this.state.nextaction} loanid={this.state.selectedloanid} />
                  
            </Modal>  

            <Modal              
              visible={this.state.mdiguarantorformvisible}
              title={"Mdi (Sacco) Guarantor Form"}
              onCancel={this.closemdiguarantorform}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={this.closemdiguarantorform}>
                  Cancel
                </Button>
                ]}
                >
                <MdiGuarantorForm loanid={this.state.selectedloanid} />

                
            </Modal>  
 

        </div>
    )


    }


    
  }
}

export default LoanApplicationsList; 
