import React from 'react';
import axios from 'axios';
import { Card, Button,message} from 'antd';
import { Row, Col } from 'antd';
import { Form, Input,Select,Spin } from 'antd';

import ExpenseCategoryForm from '../components/ExpenseCategoryForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class ExpenseCategoryDetail extends React.Component{

    state={
        expensecategory: {},
        categoryname:'' ,  
        description:'',
        datarequested:true,

    }

    componentDidMount(){
          if(localStorage.getItem("token")){
            token= localStorage.getItem("token")
          }else{
            token= ''
          }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        const expensecategoryID=this.props.match.params.expensecategoryID;
        axios.get(`${serverconfig.backendserverurl}/api/expensecategories/${expensecategoryID}`)
        .then(res => {  
            this.setState({
                expensecategory: res.data
            })

            this.setState({categoryname:res.data.category_name})
            this.setState({description:res.data.description}) 

            this.setState({datarequested:false})

        })
    }

    handlecategorynameChange= (e) => this.setState({ categoryname: e.target.value});
    handledescriptionChange= (e) => this.setState({ description: e.target.value});
  
    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const expensecategoryID=this.props.match.params.expensecategoryID;

    let form_data = new FormData();
    form_data.append('category_name', this.state.categoryname);
    form_data.append('description', this.state.description);


    axios.put(`${serverconfig.backendserverurl}/api/expensecategories/${expensecategoryID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>this.setState({datarequested:false})
, window.location.reload(false),message.info('Successfully updated'))
    .catch(error => console.log(error))

  }


    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card title={this.state.expensecategory.category_name}>
                        <p>Description: {this.state.expensecategory.description}</p>
                    </Card>
                    </Col>
    
                    <Col xs="12" sm="6" lg="4" style={{margin:6}}>
                    <Card 
                    style={{padding:10}}
                    hoverable>
                        <h6>Expense Category Update</h6>
                        <Form>
                        <FormItem label="Category Name">
                            <Input name="categoryname"  placeholder="Put a name here." value={this.state.categoryname} onChange={this.handlecategorynameChange} />
                        </FormItem>
                        <FormItem label="Description">
                            <Input name="description"  placeholder="Description here" value={this.state.description} onChange={this.handledescriptionChange} />
                        </FormItem>
                        
                        <FormItem>
                            <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                        </FormItem>
                        </Form>
    
                  </Card>
    
                    </Col>
    
                    </Row>
     
                </div>
            )


          }
       
    }
}

export default ExpenseCategoryDetail; 