import React from 'react';
import IncomeForm from '../components/IncomeForm'
import axios from 'axios'
import { Table, Input,Form, Button,DatePicker,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,PrinterOutlined,FundViewOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import IncomePaymentForm from '../components/IncomePaymentForm'
import moment from 'moment';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { RangePicker } = DatePicker;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

function callback(key) {
  console.log(key);
}


var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       bizuserid= ''
    
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

  }

  render() {
    return (
      <div style={{padding:20}}>
<Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.sacco_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
                <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
              
                </Col>

     </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>INCOMES REPORT FROM {this.props.dateone}  TO: {this.props.datetwo}</h3>

           <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Income</th>
                          <th>Income From</th>
                          <th>Total</th>
                          <th>Paid</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.incomes.map(
                        (item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.income_item}</td>
                          <td>{item.incomefrom_name}</td>
                          <td>{<CurrencyFormat value={item.total_to_be_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totaltobepaid} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalpaid} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table> 

      </div>
    );
  }
}




class IncomeList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    incomes: [],
    viewpaymentmodal:false,
    incomedetails:{},
    userprofile:{},
    datarequested:true,
    userrights:{},
    datasearch:false,
    dateone:'',
    datetwo:'',
    totaltobepaid:0,
    totalpaid:0,
    totalbalance:0
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/incomes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          incomes:res.data
        })
    })

    //getting the user profile
    let form_data = new FormData();
    form_data.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })


    })


    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
        this.setState({datarequested:false})
    })

}

////////////////////////////////////////////////////
 /// PAYMENT METHODS BEGIN
 showpaymentmodal=(id)=>{
  //now proceed to get the data for the product
  axios.get(`${serverconfig.backendserverurl}/api/incomes/${id}`)
  .then(res => {  
      this.setState({
        incomedetails:res.data
      })        
  })

  axios.get(`${serverconfig.backendserverurl}/api/incomepayments/?Income=${id}`)
  .then(res => {  
    const totalPaid =res.data.reduce((totalPaid, item) => totalPaid + item.amount, 0);
    this.setState({
      incomeTotalPayment:totalPaid
    })

  })



this.setState({ viewpaymentmodal: true });

}

closepaymentmodal = () => {
this.setState({ viewpaymentmodal: false });
}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    let form_data = new FormData();
    form_data.append('incomeid', id);
    form_data.append('sacco',sacco);
    
    axios.post(serverconfig.backendserverurl+'/customqueries/deleteincome', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => window.location.reload(false),message.info('Success fully deleted Income')
  )
  .catch(error => console.log(error))
   
  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};


handledatechange= (date, dateString) =>{
  this.setState({ dateone: dateString[0]});
  this.setState({ datetwo: dateString[1]});
} 


  //search sales method
  searchSales=()=>{
  let form_data = new FormData();
  form_data.append('dateone', this.state.dateone);
  form_data.append('datetwo', this.state.datetwo);
  form_data.append('sacco', sacco);
  
  if(this.state.dateone===''||this.state.datetwo===''){
    alert("Dates missing")
  }else{
      this.setState({datarequested:true})
      this.setState({datasearch:true})

      //Now submit sale data to database
      axios.post(serverconfig.backendserverurl+'/customqueries/getincomereport', form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
      .then(res =>{
        this.setState({datarequested:false})
          this.setState({incomes:JSON.parse(res.data.report)})
          this.setState({totaltobepaid:res.data.totaltobe})
          this.setState({totalpaid:res.data.totalpaid})
          this.setState({totalbalance:res.data.totalbal})
      } 
      )
      .catch(error => console.log(error))   
  }

  }




  render() {

    if(this.state.userprofile.branch_manager){
      var columns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Income Item',
          dataIndex: 'income_item',
          key: 'id',
          ...this.getColumnSearchProps('income_item'),
        },
        {
          title: 'Income From',
          dataIndex: 'incomefrom_name',
          key: 'id',
        },
        {
          title: 'Amount',
          dataIndex: 'total_to_be_paid',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/incomes/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click to add payment against income</p>} title="Payment">
            <WalletFilled style={{color:'blue'}} onClick={() => this.showpaymentmodal(text)}/>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to delete income</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>
  
          </p>,
        }
  
      ];
    }else{
      var columns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Income Item',
          dataIndex: 'income_item',
          key: 'id',
          ...this.getColumnSearchProps('income_item'),
        },
        {
          title: 'Income From',
          dataIndex: 'incomefrom_name',
          key: 'id',
        },
        {
          title: 'Amount',
          dataIndex: 'total_to_be_paid',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/incomes/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click to add payment against income</p>} title="Payment">
            <WalletFilled style={{color:'blue'}} onClick={() => this.showpaymentmodal(text)}/>
          </Popover>
          </p>,
        }
  
      ];
    }  
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>

            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              incomes={this.state.incomes} 
              totalbalance={this.state.totalbalance} 
              totalpaid={this.state.totalpaid}
              totaltobepaid={this.state.totaltobepaid}
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              ref={el => (this.componentRef = el)} /> 
            </div>


            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="AVAILABLE INCOMES" key="1">
            <Form  layout="inline" >
              <FormItem label="Search by Date Range">
                  <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
              </FormItem>

              <FormItem>
              <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
              </FormItem> 
            </Form>


            {
              this.state.datasearch?
              <div>
              <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Income</th>
                          <th>Income From</th>
                          <th>Total</th>
                          <th>Paid</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.incomes.map(
                        (item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.income_item}</td>
                          <td>{item.incomefrom_name}</td>
                          <td>{<CurrencyFormat value={item.total_to_be_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totaltobepaid} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalpaid} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table> 


                <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
               </div>
              </div>

              :
              <Table   
              columns={columns} 
              scroll={{ x: 1000 }}
              dataSource={this.state.incomes} 
              pagination={{showQuickJumper:true,showSizeChanger:true }}
              bordered/>
            }


            <br />
            </Panel>
            </Collapse>
            <br></br>
              {
                this.state.userrights.can_enter_incomes?
                <Collapse defaultActiveKey={['0']} onChange={callback}>
                <Panel header="Collapse Panel to open Form" key="1">
                  <h2>Create an income here</h2>
                  <IncomeForm 
                  requestType="post"
                  expenseID={null}
                  btnText="Create"
                  /> 
                </Panel>
                </Collapse>
                :
                null
              }



                {/* Product details modal */}
                <Modal
                  visible={this.state.viewpaymentmodal}
                  title="Expense Payment"
                  width={600}
                  onCancel={this.closepaymentmodal}
                  footer={[
                    <Button key="back" onClick={this.closepaymentmodal}>
                      Cancel
                    </Button>,
                    ]}
                    >
                 <Descriptions title="">
                   <Descriptions.Item label="Total"><CurrencyFormat value={this.state.incomedetails.total_to_be_paid} displayType={'text'} thousandSeparator={true}   /></Descriptions.Item>
                    <Descriptions.Item label="Paid Amount"><CurrencyFormat value={this.state.incomeTotalPayment} displayType={'text'} thousandSeparator={true}   /></Descriptions.Item>
                    <Descriptions.Item label="Due Amount"><CurrencyFormat value={Number(this.state.incomedetails.total_to_be_paid)-Number(this.state.incomeTotalPayment)} displayType={'text'} thousandSeparator={true}   /></Descriptions.Item>
                </Descriptions>
                <br></br>
                <IncomePaymentForm incomeid={this.state.incomedetails.id} />


              </Modal>

        </div>
    )


    }


    
  }
}

export default IncomeList; 
