import React from 'react';
import { Form, Input, Button,Select,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var sacco= ''
var token= ''

class AccountTypeForm extends React.Component {

  state = {
    account_type_name:'',
    minimum_account_balance:0,
    deposit_charges:0,
    minimum_account_deposit:0,
    withdraw_charges:0,
    withdrawals_per_month:0,
    account_fine_fee:0,
    account_transfer_charge:0,
    account_monthly_charge:0,
    account_monthly_interest_rate:0,
    account_statement_fee:0,
    cheque_deposit_fee:0,
    reactivation_charge:0,
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }
    


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false})
}


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('account_type_name', this.state.account_type_name);
    form_data.append('minimum_account_balance', this.state.minimum_account_balance);
    form_data.append('deposit_charges', this.state.deposit_charges);
    form_data.append('minimum_account_deposit', this.state.minimum_account_deposit);
    form_data.append('withdraw_charges', this.state.withdraw_charges);
    form_data.append('withdrawals_per_month', this.state.withdrawals_per_month);
    form_data.append('account_fine_fee', this.state.account_fine_fee);
    form_data.append('account_transfer_charge', this.state.account_transfer_charge);
    form_data.append('account_monthly_charge', this.state.account_monthly_charge);
    form_data.append('account_monthly_interest_rate', this.state.account_monthly_interest_rate);
    form_data.append('account_statement_fee', this.state.account_statement_fee);
    form_data.append('cheque_deposit_fee', this.state.cheque_deposit_fee);
    form_data.append('reactivation_charge', this.state.reactivation_charge);
    form_data.append('sacco', sacco);
    
    axios.post(serverconfig.backendserverurl+'/api/accounttypes/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>         this.setState({datarequested:false})
,  window.location.reload(false)
  )
  .catch(error => console.log(error))

  }


  handleaccount_type_nameChange= (e) => this.setState({ account_type_name: e.target.value});
  handleminimum_account_balanceChange= (e) => this.setState({ minimum_account_balance: e.target.value});
  handledeposit_chargesChange= (e) => this.setState({ deposit_charges: e.target.value});
  handleminimum_account_depositChange= (e) => this.setState({ minimum_account_deposit: e.target.value});
  handlewithdraw_chargesChange= (e) => this.setState({ withdraw_charges: e.target.value});
  handlewithdrawals_per_monthChange= (e) => this.setState({ withdrawals_per_month: e.target.value});
  handleaccount_fine_feeChange= (e) => this.setState({ account_fine_fee: e.target.value});
  handleaccount_transfer_chargeChange= (e) => this.setState({ account_transfer_charge: e.target.value});
  handleaccount_monthly_chargeChange= (e) => this.setState({ account_monthly_charge: e.target.value});
  handleaccount_monthly_interest_rateChange= (e) => this.setState({ account_monthly_interest_rate: e.target.value});
  handleaccount_statement_feeChange= (e) => this.setState({ account_statement_fee: e.target.value});
  handlecheque_deposit_feeChange= (e) => this.setState({ cheque_deposit_fee: e.target.value});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
          <Form
            onFinish={(event) => this.handleFormSubmit(event)} 
          >
            <FormItem label="Account Type Name"
              name="account_type_name"
              rules={[
                {
                  required: true,
                  message: 'Please enter name',
                },
              ]}             
            >
              <Input name="account_type_name"  placeholder="Put a name here." value={this.state.account_type_name} onChange={this.handleaccount_type_nameChange} />
            </FormItem>

            <FormItem label="Minimum Account Balance"
              name="minimum_account_balance"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="minimum_account_balance"  placeholder="0.00" value={this.state.minimum_account_balance} onChange={this.handleminimum_account_balanceChange} />
            </FormItem>
  
            <FormItem label="Deposit Charges"
              name="deposit_charges"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="deposit_charges"  placeholder="0.00" value={this.state.deposit_charges} onChange={this.handledeposit_chargesChange} />
            </FormItem>
  
            <FormItem label="Minimum Account Deposit"
              name="minimum_account_deposit"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="minimum_account_deposit"  placeholder="0.00" value={this.state.minimum_account_deposit} onChange={this.handleminimum_account_depositChange} />
            </FormItem>


            <FormItem label="Withdraw Charges"
              name="withdraw_charges"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="withdraw_charges"  placeholder="0.00" value={this.state.withdraw_charges} onChange={this.handlewithdraw_chargesChange} />
            </FormItem>

            <FormItem label="Withdrawals per month"
              name="withdrawals_per_month"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="withdrawals_per_month"  placeholder="0" value={this.state.withdrawals_per_month} onChange={this.handlewithdrawals_per_monthChange} />
            </FormItem>

            <FormItem label="Account Fine Fee"
              name="account_fine_fee"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="account_fine_fee"  placeholder="0" value={this.state.account_fine_fee} onChange={this.handleaccount_fine_feeChange} />
            </FormItem>

            <FormItem label="Account Transfer Charge"
              name="account_transfer_charge"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="account_transfer_charge"  placeholder="0.00" value={this.state.account_transfer_charge} onChange={this.handleaccount_transfer_chargeChange} />
            </FormItem>

            <FormItem label="Account Monthly Charge"
              name="account_monthly_charge"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="account_monthly_charge"  placeholder="0.00" value={this.state.account_monthly_charge} onChange={this.handleaccount_monthly_chargeChange} />
            </FormItem>

            <FormItem label="Account Monthly Interest Rate"
              name="account_monthly_interest_rate"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="account_monthly_interest_rate"  placeholder="0.00" value={this.state.account_monthly_interest_rate} onChange={this.handleaccount_monthly_interest_rateChange} />
            </FormItem>

            <FormItem label="Account Statement Fee"
              name="account_statement_fee"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="account_statement_fee"  placeholder="0.00" value={this.state.account_statement_fee} onChange={this.handleaccount_statement_feeChange} />
            </FormItem>

            <FormItem label="Cheque Deposit Fee"
              name="cheque_deposit_fee"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="cheque_deposit_fee"  placeholder="0.00" value={this.state.cheque_deposit_fee} onChange={this.handlecheque_deposit_feeChange} />
            </FormItem>

            <FormItem label="Reactivation Charge"
              name="reactivation_charge"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="reactivation_charge"  placeholder="0.00" value={this.state.reactivation_charge} onChange={this.handlereactivation_chargeChange} />
            </FormItem>

            <FormItem>
              <Button  type="primary" htmlType="submit">Create</Button>
            </FormItem>
          </Form>
        </div>
      );


    }


    
  }

}


export default AccountTypeForm;



