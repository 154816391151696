import React from 'react';
import { Form, Input, Button,Spin,DatePicker } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var token= ''
var sacco= ''


class NonWorkingDayForm extends React.Component {
  state = {
    date:moment().format(dateFormat).toString(),  
    event:'',
    datarequested:false,
  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('event', this.state.event);
    form_data.append('sacco', sacco);

      axios.post(serverconfig.backendserverurl+'/api/nonworkingdays/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }


  handleeventChange= (e) => this.setState({ event: e.target.value});
  handledatechange= (date, dateString) => this.setState({ date: dateString});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >

    <FormItem label="Date"
            name='date'
            rules={[
              {
                required: true,
                message: 'Please Select date',
              },
            ]}
          >
              <DatePicker onChange={this.handledatechange} format={dateFormat} />
          </FormItem>


      <FormItem label="Event"
        name="event"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="event"  placeholder="Put event here." value={this.state.event} onChange={this.handleeventChange} />
      </FormItem>


     
      <FormItem>
        <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
      </FormItem>
    </Form>
        </div>
      );

    }

   
  }

}


export default NonWorkingDayForm;



