import React from 'react';
import { Form, Input, Button,Select,Spin,Card,Result } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var token= ''
var sacco= ''


class MdiGuarantorForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    assettype:'',
    assetvalue:'',
    datarequested:true,
    selectedloan:'',
    datasubmittedsuccessfully:false,
    members: [],
    assetname:'',
    account:'',
    accountobj:{},

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({selectedloan:this.props.loanid})
    console.log("selected id: "+this.props.loanid)

    axios.get(serverconfig.backendserverurl+`/api/idtypes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          idtypes:res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

        this.setState({datarequested:false})
    })
    
}


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('date', this.state.date);
      form_data.append('account', this.state.account);
      form_data.append('loanapplication', this.state.selectedloan);
      form_data.append('attached_asset_type', this.state.assettype);
      form_data.append('attached_asset', this.state.assetname);
      form_data.append('attached_asset_value', this.state.assetvalue);
      form_data.append('sacco', sacco);

      axios.post(serverconfig.backendserverurl+'/api/mdiguarantors/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>{
      this.setState({datarequested:false})
      this.setState({datasubmittedsuccessfully:true})
    }         
    )
    .catch(error => console.log(error))

  }

  handleassetnameChange= (e) => this.setState({ assetname: e.target.value});
  handleassetvalueChange= (e) => this.setState({ assetvalue: e.target.value});

  handleassettypeChange= (e) =>{
    this.setState({ assettype: e});

    if (e==="Savings"){
      this.setState({assetvalue:this.state.accountobj.UserBalance})
      this.setState({assetname:"Savings"})
    }

  } 



  handleaccountChange= (e) =>{
    this.setState({assetvalue:''})
    this.setState({assetname:""})
    this.setState({assettype:""})


    this.setState({ account: e});
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
    .then(res => {  
        this.setState({accountobj: res.data})
    })

  } 



  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){

        return(
          <Card>
          <Result
          status="success"
          title="Successfully Created MDI Guarantor "
          subTitle="MDI/Sacco guarantor guarantee loan disburshments in institutions"
          extra={[
            <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
          ]}
          />
          </Card>
      );
      


          }else{

         return (
          <div>
            <Form
              onFinish={(event) => this.handleFormSubmit(event)} 
            >
            <FormItem label="Account"
                name="account"
                rules={[
                  {
                    required: true,
                    message: 'Please select account',
                  },
                ]}               
            
            >
                <Select 
                placeholder="Account" 
                style={{  }} value={this.state.account} 
                onChange={this.handleaccountChange} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                >
                  {this.state.members.map(
                    (stf)=>(
                      <Option value={stf.id}>{stf.AccountNumber} | {stf.FullName} </Option>
                    ))}
                </Select>
            </FormItem>
  
              <FormItem label="Asset Type"
                name="assettype"
                rules={[
                  {
                    required: true,
                    message: 'Please select asset',
                  },
                ]}           
              
              >
                  <Select placeholder="Asset" style={{ width: 120 }} value={this.state.assettype} onChange={this.handleassettypeChange} >
                        <Option value="Savings">Savings</Option>
                        <Option value="OtherAsset">Other Asset</Option>
                  </Select>
              </FormItem>

              <FormItem label="Asset Name">
                  <Input  placeholder="Asset Name." value={this.state.assetname} onChange={this.handleassetnameChange} />
              </FormItem>              
  
              <FormItem label="Asset Value">
                  <Input  placeholder="Asset Value." value={this.state.assetvalue} onChange={this.handleassetvalueChange} />
              </FormItem>
  
              <FormItem>
                <Button  type="primary" htmlType="submit">Add</Button>
              </FormItem>
            </Form>
          </div>
        );

      }

    }

  }

}


export default MdiGuarantorForm;



