import React from 'react';
import { Form, Input, Button,Select,message,Spin,Checkbox,Result } from 'antd';
import { Table,Collapse,Popover,Popconfirm,Descriptions,Modal } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';

import { SearchOutlined,LoadingOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,EditOutlined } from '@ant-design/icons';

const dateFormat = 'HHmmssDDMMYYYY';

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class Saccocreateform extends React.Component {

  state = {
    companyname:'',  
    licence:'',
    companyemail:'',
    companylogo:null,   
    companyphone:'',
    boxnumber:'',
    accountslimit:0,
    branchname:'',
    companyphonetwo:'',
    companyphonethree:'',
    website:'',
    address:'',
    datarequested:true,  
    safebalance:0,
    institutioanlcapital:0,
    surplus:0,
    retainedearnings:0,
    packages:[],
    package:'',

    isactive:false,
    ismanager:false,
    profilepic:null,  
    successfullsubmission:false
  }


  componentDidMount(){

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
    
    axios.get(serverconfig.backendserverurl+'/api/packages/')
    .then(res => {
        this.setState({
          packages:res.data
        })

        this.setState({datarequested:false});
    })
  }

  onActiveCheckboxChange = e => {
    this.setState({isactive:e.target.checked});
  };

  onManagerCheckboxChange = e => {
    this.setState({ismanager:e.target.checked});
  };

  handleprofilepicChange= (e) => this.setState({ profilepic: e.target.files[0]});


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('sacco_name', this.state.companyname);
    form_data.append('branchname', this.state.branchname);
    form_data.append('company_email', this.state.companyemail);
    form_data.append('RegisteredPhoneNumber1', this.state.companyphone);
    form_data.append('box_number', this.state.boxnumber);
    form_data.append('licencedays', 30);
    form_data.append('accountslimit',1);

    form_data.append('website',this.state.website);
    form_data.append('RegisteredPhoneNumber2', this.state.companyphonetwo);
    form_data.append('RegisteredPhoneNumber3', this.state.companyphonethree);

    form_data.append('safe_balance', 0);
    form_data.append('institutional_capital', 0);
    form_data.append('surplus', 0);
    form_data.append('retained_earnings', 0);
    form_data.append('country', 'Uganda');
    form_data.append('currency', 'UGX');
    form_data.append('address',this.state.address);
    form_data.append('package',this.state.package);

    //managerial data now
    form_data.append('username', event.username);
    form_data.append('email', event.email,);
    form_data.append('password1', event.password);
    form_data.append('password2', event.confirm);
    form_data.append('is_active', true);
    form_data.append('branch_manager', true);

      axios.post(serverconfig.backendserverurl+'/customqueries/createsaccobyclient', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => this.setState({datarequested:false})
    ,message.info('Successfully created sacco'),this.setState({successfullsubmission:true})
    )
    .catch(error => console.log(error))

  }

  handleCompanyNameChange= (e) => this.setState({ companyname: e.target.value});
  handleLicenceChange= (e) => this.setState({ licence: e.target.value});
  handleCompanyEmailChange= (e) => this.setState({ companyemail: e.target.value});
  handleCompanyLogoChange= (e) => this.setState({ companylogo: e.target.files[0]});

  handleCompanyPhoneChange= (e) => this.setState({ companyphone: e});
  handleCompanyBoxNumberChange= (e) => this.setState({ boxnumber: e.target.value});
  handleaccountslimitChange=(e) => this.setState({ accountslimit: e.target.value});
  handlebranchnameChange=(e) => this.setState({ branchname: e.target.value});
  handleentitytypeChange= (e) => this.setState({ entitytype: e});
  
  handleCompanyPhonetwoChange= (e) => this.setState({ companyphonetwo: e});
  handleCompanyPhonethreeChange= (e) => this.setState({ companyphonethree: e});
  handlecompanydealershipChange= (e) => this.setState({ companydealership: e.target.value});
  handlewebsiteChange= (e) => this.setState({ website: e.target.value});
  handleappbannerChange= (e) => this.setState({ appbanner: e.target.files[0]});
  handlevendorpaymentvalueChange=(e)=>this.setState({vendorpaymentvalue:e.target.value})
  
  handledeliveryagencyChange= (e) => this.setState({ deliveryagency: e});

  handleAddressChange= (e) => this.setState({ address: e.target.value});
  handleretainedearningsChange= (e) => this.setState({ retainedearnings: e.target.value});
  handlesurplusChange= (e) => this.setState({ surplus: e.target.value});
  handleinstitutioanlcapitalChange= (e) => this.setState({ institutioanlcapital: e.target.value});
  handlesafebalanceChange= (e) => this.setState({ safebalance: e.target.value});
  
  handlepackageChange= (e) => this.setState({ package: e});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if(this.state.successfullsubmission===true){
        return (
          <Result
          status="success"
          title="Successfully Registered Your Sacco"
          subTitle="Please login now using submitted managerial credentials"
          />
        )

      }else{
        return (
          <div >
            <Form
            onFinish={(event) => this.handleFormSubmit(event)}
            >
              <h3>Sacco KYC</h3>
  
              <FormItem label="Sacco Name"
                  name="sacconame"
                  rules={[
                    {
                      required: true,
                      message: 'Please input name',
                    },
                  ]}
                >
                <Input name="sacconame"  placeholder="Put a name here." value={this.state.companyname} onChange={this.handleCompanyNameChange} />
              </FormItem>
    
    
              <FormItem label="Branch Name"
                  name="branchname"
                  rules={[
                    {
                      required: true,
                      message: 'Please input branch name',
                    },
                  ]}
                >
                <Input  placeholder="Put a name here." value={this.state.branchname} onChange={this.handlebranchnameChange} />
              </FormItem>
    
              
              <FormItem label="Sacco Email"
                  name="companyemail"
                  rules={[
                    {
                      required: true,
                      message: 'Please input email',
                    },
                  ]}
              >
                <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.companyemail} onChange={this.handleCompanyEmailChange} />
              </FormItem> 
    
              <FormItem label="Phone Number">
              <PhoneInput
                    countrySelectProps={{ unicodeFlags: true }}
                    defaultCountry="UG"
                    placeholder="Enter phone number"
                    value={this.state.companyphone} onChange={this.handleCompanyPhoneChange}/>
               </FormItem>
               <FormItem label="Phone Number Two">
              <PhoneInput
                    countrySelectProps={{ unicodeFlags: true }}
                    defaultCountry="UG"
                    placeholder="Enter phone number"
                    value={this.state.companyphonetwo} onChange={this.handleCompanyPhonetwoChange}/>
               </FormItem> 
    
               <FormItem label="Phone Number Three">
              <PhoneInput
                    countrySelectProps={{ unicodeFlags: true }}
                    defaultCountry="UG"
                    placeholder="Enter phone number"
                    value={this.state.companyphonethree} onChange={this.handleCompanyPhonethreeChange}/>
               </FormItem>  
               <FormItem label="Website">
                <Input  name="website"  placeholder="Webiste here" value={this.state.website} onChange={this.handlewebsiteChange} />
              </FormItem>
    
              <FormItem label="Box number">
                <Input name="boxnumber"  placeholder="Box number here" value={this.state.boxnumber} onChange={this.handleCompanyBoxNumberChange} />
              </FormItem>
    
              <FormItem label="Address">
                <Input name="address"  placeholder="Address here" value={this.state.address} onChange={this.handleAddressChange} />
              </FormItem>
  
              <FormItem label="Package"
              name="package"
              rules={[
                {
                  required: true,
                  message: 'Please select package',
                },
              ]}            
              
              >
                  <Select placeholder="Package"  value={this.state.package} onChange={this.handlepackageChange} >
                    {this.state.packages.map(
                      (pckg)=>(
                        <Option value={pckg.id}>{pckg.package_name}</Option>
                      ))}
                  </Select>
              </FormItem>
  
  
  
              <h3>User Managerial KYC</h3>
              <Form.Item
              label="User Name"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username"    />
            </Form.Item>
  
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input type="mail" prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email"   />
  
            </Form.Item>
      
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password 
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              />
  
  
            </Form.Item>
  
      
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
      
                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
            >
              <Input.Password 
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password" />
  
            </Form.Item>
  
  
              <FormItem>
                <Button type="primary" htmlType="submit" >Submit</Button>
              </FormItem>
            </Form>
          </div>
        );

      }

     
    }


   
  }

}


export default Saccocreateform;



