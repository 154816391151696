import React from 'react';
import axios from 'axios';
import { Card,Avatar} from 'antd';
import { Form, Input, Button,Select,Spin,Image,DatePicker,Result } from 'antd';
import {
    Col,
    Row,
  } from 'reactstrap';
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import {  LockOutlined,MailOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input'
import CurrencyFormat from 'react-currency-format';

import * as serverconfig from '../serverconn'
import moment from 'moment';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';


var token= ''
var bizuserid= ''
var sacco= ''


class GroupDetail extends React.Component{

    state={
        group: {},
        accounttypes:[],
        groupname:'' ,  
        District:'',
        subcounty:'',
        parish:'',
        village:'',
        account_type:'',
        accountnumber:'',
        noofmembers:0,
        nooffemales:0,
        noofmales:0,
        MembersFile:null,
        datarequested:true,
        datasubmittedsuccessfully:false,
        saccopackageobj:{},
        members:[],
        saccoboject:{},
        RegisteredPhoneNumber1:'',
        RegisteredPhoneNumber2:'',       
        datasubmittedsuccessfully:false,
        userrights:{},
        all_group_members:null
    }


    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      }else{
         sacco= ''
      }
      
      if(localStorage.getItem("bizuserid")){
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      }else{
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }



        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }


        const groupID=this.props.match.params.groupID;
        axios.get(`${serverconfig.backendserverurl}/api/groups/${groupID}`)
        .then(res => {  
            this.setState({group: res.data})

            this.setState({groupname: res.data.group_name})
            this.setState({District: res.data.district})
            this.setState({subcounty: res.data.subcounty})
            this.setState({parish: res.data.parish})
            this.setState({village: res.data.area_of_origin})
            this.setState({account_type: res.data.accountType})
            this.setState({accountnumber: res.data.group_account})
            this.setState({noofmembers: res.data.number_of_members})
            this.setState({nooffemales: res.data.number_of_females})
            this.setState({noofmales: res.data.number_of_males})
            this.setState({all_group_members: res.data.all_group_members})

            this.setState({RegisteredPhoneNumber1: res.data.RegisteredPhoneNumber1})
            this.setState({RegisteredPhoneNumber2: res.data.RegisteredPhoneNumber2})

        })


        axios.get(serverconfig.backendserverurl+`/api/accounttypes/?sacco=${sacco}`)
        .then(res => {
            this.setState({
              accounttypes:res.data
            })
        })
    

        axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
        .then(res => {
            this.setState({
              userrights:res.data[0]
            })
            this.setState({datarequested:false})
        })

    }

    //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    const groupID=this.props.match.params.groupID;

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('group_name', this.state.groupname);
    form_data.append('district', this.state.District);
    form_data.append('subcounty', this.state.subcounty);
    form_data.append('parish', this.state.parish);
    form_data.append('accountType', this.state.account_type);
    form_data.append('area_of_origin', this.state.village);
    form_data.append('group_account', this.state.accountnumber);
    form_data.append('number_of_members', this.state.noofmembers);
    form_data.append('number_of_females', this.state.nooffemales);
    form_data.append('number_of_males', this.state.noofmales);
    form_data.append('sacco', sacco);
    form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
    form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);

    this.state.MembersFile==null?
    console.log("No Members file")
    :
    form_data.append('all_group_members', this.state.MembersFile, this.state.MembersFile?this.state.MembersFile.name:"");
  
  axios.put(serverconfig.backendserverurl+`/api/groups/${groupID}/`, form_data, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
  .then(res =>{
    if(this.isEmpty(res.data)===false){
      let form_data1 = new FormData();
      form_data1.append('date', this.state.date);
      form_data1.append('group_name', this.state.groupname);
      form_data1.append('district', this.state.District);
      form_data1.append('subcounty', this.state.subcounty);
      form_data1.append('parish', this.state.parish);
      form_data1.append('accountType', this.state.account_type);
      form_data1.append('area_of_origin', this.state.village);
      form_data1.append('group_account', this.state.accountnumber);
      form_data1.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
      form_data1.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);
      form_data1.append('groupID', groupID);

      form_data1.append('sacco', sacco);

      axios.post(serverconfig.backendserverurl+'/customqueries/updategroupaccount', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        if(res.data.message==="success"){
          this.setState({datarequested:false})
          this.setState({datasubmittedsuccessfully:true})
        }
      })
      .catch(error => console.log(error))
    }

  })
  .catch(error => console.log(error))
  }

  //check empty object
 isEmpty=(obj)=> {
  for(var prop in obj) {
      if(obj.hasOwnProperty(prop))
          return false;
  }

  return true;
}


 
handlegroupnameChange= (e) => this.setState({ groupname: e.target.value});
handleDistrictChange= (e) => this.setState({ District: e.target.value});
handlesubcountyChange= (e) => this.setState({ subcounty: e.target.value});
handleparishChange= (e) => this.setState({ parish: e.target.value});
handleaccount_typeChange= (e) => this.setState({ account_type: e});
handlevillageChange= (e) => this.setState({ village: e.target.value});
handleaccountnumberChange= (e) => this.setState({ accountnumber: e.target.value});
handlenoofmembersChange= (e) => this.setState({ noofmembers: e.target.value});
handlenooffemalesChange= (e) => this.setState({ nooffemales: e.target.value});
handlenoofmalesChange= (e) => this.setState({ noofmales: e.target.value});
handleMembersFileChange= (e) =>{
  this.setState({ MembersFile: e.target.files[0]});
} 

handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});
handleRegisteredPhoneNumber2Change= (e) => this.setState({ RegisteredPhoneNumber2: e});



    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{

            if (this.state.datasubmittedsuccessfully===true){
              return(
                  <Card>
                  <Result
                  status="success"
                  title="Successfully Updated Group Account details "
                  subTitle="Group details can be updated any time"
                  extra={[
                    <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                  ]}
                  />
                  </Card>
              );
      
            }else{


              return(
                <div>

            <Row>
            <Col xs="12" sm="6" lg="6">

            <Card 
              style={{padding:10}}
              hoverable>

                <h4 style={{fontWeight:'bold'}}>Group Update </h4>
                <Form
                    onFinish={(event) => this.handleFormSubmit(event)} 
                    >

                  <h3>Group Detail Section</h3>
                  <FormItem label="Group Name"
       
                  >
                    <Input  placeholder="Put a name here." value={this.state.groupname} onChange={this.handlegroupnameChange} />
                  </FormItem>


                  <FormItem label="District"

                  >
                    <Input  placeholder="District." value={this.state.District} onChange={this.handleDistrictChange} />
                  </FormItem>
      
                  <FormItem label="Subcounty"

                  >
                    <Input  placeholder="Subcounty." value={this.state.subcounty} onChange={this.handlesubcountyChange} />
                  </FormItem>
      
                  <FormItem label="Parish"

                  >
                    <Input  placeholder="Parish." value={this.state.parish} onChange={this.handleparishChange} />
                  </FormItem>
      
                  <FormItem label="Village"
              
                  >
                    <Input  placeholder="Vallage." value={this.state.village} onChange={this.handlevillageChange} />
                  </FormItem>

                  <FormItem label="Account Type"
         
                  >
                      <Select placeholder="Account Type" style={{  }} value={this.state.account_type} onChange={this.handleaccount_typeChange} >
                        {this.state.accounttypes.map(
                          (stf)=>(
                            <Option value={stf.id}>{stf.account_type_name}</Option>
                          ))}
                      </Select>
                  </FormItem>
      
                  <FormItem label="Account Number"
                  >
                    <Input placeholder="Account Number." value={this.state.accountnumber} onChange={this.handleaccountnumberChange} />
                  </FormItem>
                  
                  <FormItem label="No Of Members">
                    <Input placeholder="Total number of members" value={this.state.noofmembers} onChange={this.handlenoofmembersChange} />
                  </FormItem>
                   
      
                  <FormItem label="No Of Males">
                    <Input placeholder="Total Number Of Males." value={this.state.noofmales} onChange={this.handlenoofmalesChange} />
                  </FormItem>
      
                  <FormItem label="No Of Females">
                    <Input placeholder="Total Number Females." value={this.state.nooffemales} onChange={this.handlenooffemalesChange} />
                  </FormItem>
      
                  <FormItem label="Members Files" style={{marginRight:3}}>
                        <Input type="file" accept="*" placeholder="Members File"  onChange={this.handleMembersFileChange}/>
                  </FormItem> 

                  <h3>Group Account Contacts</h3>

                  <FormItem label="Phone One"                    
                  >
                  <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber1} onChange={this.handleRegisteredPhoneNumber1Change}/>
                   </FormItem> 
        
                   <FormItem label="Phone Two">
                   <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber2} onChange={this.handleRegisteredPhoneNumber2Change}/>
                   </FormItem>
    
                    {
                    this.state.userrights.editaccounts?
                      <FormItem>
                      <Button  type="primary" htmlType="submit">Update</Button>
                      </FormItem>
                    :
                      null
                    }

                </Form>
              
              </Card>
                      
                    </Col>

                  {
                    this.state.all_group_members===null?
                    null
                    :

                    <Col xs="12" sm="6" lg="4">
                    <Card 
                      style={{padding:10}}
                      hoverable>
                      <Button type='primary'>
                      <a href={this.state.group.all_group_members}>Download Members File</a>
                      </Button>
                          
                    </Card>
                    </Col>
                  }

                  
                   </Row>
    
                </div>
            )
            }

          }

    }
}

export default GroupDetail; 