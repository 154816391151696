import React from 'react';
import IncomeForm from '../components/IncomeForm'
import axios from 'axios'
import { Table, Input,Form, Button,Select,DatePicker,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,PlusOutlined,DeleteOutlined,EditOutlined,PrinterOutlined,FundViewOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import moment from 'moment';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { RangePicker } = DatePicker;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

function callback(key) {
  console.log(key);
}

var token= ''
var sacco= ''
var bizuserid= ''
const { TabPane } = Tabs;
const { Option } = Select;


class TransactionList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    viewpaymentmodal:false,
    incomedetails:{},
    userprofile:{},
    datarequested:true,
    userrights:{},
    datasearch:false,
    dateone:'',
    datetwo:'',
    totaltobepaid:0,
    totalpaid:0,
    totalbalance:0,
    transactions:[],
    till:[],
    incomes:[],
    loanapplications:[],
    loanrepayments:[],
    members:[],
    updateformvisible:false,
    selectedaccountobject:{},
    accountbalance:0,
    deletelist:[],
    deletemode:'',
    editmodalvisiblae:false,
    selectedtranobj:{},
    credit:0,
    debit:0,
    balance:0,
    transdesc:''

  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/incomes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          incomes:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/transactions/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          transactions:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/till/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          till:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/incomes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          incomes:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          loanapplications:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/loanrepayments/?sacco=${sacco}&description=${'Loan Repayment'}`)
    .then(res => {
        this.setState({
          loanrepayments:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

        this.setState({datarequested:false})

    })

}


closepaymentmodal = () => {
this.setState({ viewpaymentmodal: false });
}

  

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {

      var transactioncolumns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Account No',
          dataIndex: 'AccountNumber',
          key: 'id',
          ...this.getColumnSearchProps('AccountNumber'),
        },  
        {
          title: 'Acc Name',
          dataIndex: 'FullName',
          key: 'id',
        },
        {
          title: 'Teller',
          dataIndex: 'user',
          key: 'id',
        },
        {
          title: 'Slip No',
          dataIndex: 'slip_number',
          key: 'id',
        },
        {
          title: 'Debit',
          dataIndex: 'debit',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Credit',
          dataIndex: 'credit',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Details',
          dataIndex: 'transaction_details',
          key: 'id',
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <p>
            <Popover content={<p>Click here to edit transaction</p>} title="Activate">
            <EditOutlined style={{color:'green'}} onClick={()=>{
              this.setState({editmodalvisiblae:true})
              this.setState({selectedtranobj:record})
              this.setState({credit:record.credit})
              this.setState({debit:record.debit})
              this.setState({balance:record.balance})
              this.setState({transdesc:record.transaction_details})

            }}/>
            </Popover>


          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => {
            axios.delete(`${serverconfig.backendserverurl}/api/transactions/${text}`).then(res =>{
              //window.location.reload(false)
              message.info("successfully deleted") 

              axios.get(serverconfig.backendserverurl+`/api/transactions/?sacco=${sacco}`)
              .then(res => {
                  this.setState({
                    transactions:res.data
                  })
              })

            }
            );
          }}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>

          &nbsp;&nbsp;&nbsp;
          {
            this.state.deletemode==="transactions"?
            <Popover content={<p>Click here to add to list</p>} title="Add to Delete list">
            <PlusOutlined style={{color:'green'}}  onClick={()=>{
              this.setState({
              deletelist: [...this.state.deletelist, text]
              });

            }}/>
            </Popover>
            :null
          }

          </p>,
        }
  
      ];


      var tillcolumns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Account',
          dataIndex: 'account',
          key: 'id',
          ...this.getColumnSearchProps('account'),
        },  
        {
          title: 'Teller',
          dataIndex: 'user',
          key: 'id',
        },
        {
          title: 'Debit',
          dataIndex: 'Debit',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Credit',
          dataIndex: 'Credit',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },

        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => {
            axios.delete(`${serverconfig.backendserverurl}/api/till/${text}`).then(res =>{
              //window.location.reload(false)
              message.info("successfully deleted") 

              axios.get(serverconfig.backendserverurl+`/api/till/?sacco=${sacco}`)
              .then(res => {
                  this.setState({
                    till:res.data
                  })
              })

            }
            );
          }}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>

          &nbsp;&nbsp;&nbsp;
          {
            this.state.deletemode==="till"?
            <Popover content={<p>Click here to add to list</p>} title="Add to Delete list">
            <PlusOutlined style={{color:'green'}}  onClick={()=>{
              this.setState({
              deletelist: [...this.state.deletelist, text]
              });

            }}/>
            </Popover>
            :null
          }

          </p>,
        }
  
      ];



      var incomecolumns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Income Item',
          dataIndex: 'income_item',
          key: 'id',
          ...this.getColumnSearchProps('income_item'),
        },
        {
          title: 'Income From',
          dataIndex: 'incomefrom_name',
          key: 'id',
        },
        {
          title: 'Amount',
          dataIndex: 'total_to_be_paid',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },

        {
          title: 'Details',
          dataIndex: 'details',
          key: 'id',
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => {
            axios.delete(`${serverconfig.backendserverurl}/api/incomes/${text}`).then(res =>{
              //window.location.reload(false)
              message.info("successfully deleted") 

              axios.get(serverconfig.backendserverurl+`/api/incomes/?sacco=${sacco}`)
              .then(res => {
                  this.setState({
                    incomes:res.data
                  })
              })
            }
            );
          }}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>


          &nbsp;&nbsp;&nbsp;
          {
            this.state.deletemode==="incomes"?
            <Popover content={<p>Click here to add to list</p>} title="Add to Delete list">
            <PlusOutlined style={{color:'green'}}  onClick={()=>{
              this.setState({
              deletelist: [...this.state.deletelist, text]
              });

            }}/>
            </Popover>
            :null
          }

          </p>,
        }
  
      ];


      var loanapplicationscolumns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'AccountNumber',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'AccountName',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),

        },
        {
          title: 'Loan Amount',
          dataIndex: 'loan_amount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },

        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => {
            axios.delete(`${serverconfig.backendserverurl}/api/loanapplications/${text}`).then(res =>{
              //window.location.reload(false)
              message.info("successfully deleted") 

              axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}`)
              .then(res => {
                  this.setState({
                    loanapplications:res.data
                  })
              })
            }
            );
          }}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>

          &nbsp;&nbsp;&nbsp;
          {
            this.state.deletemode==="loanaplications"?
            <Popover content={<p>Click here to add to list</p>} title="Add to Delete list">
            <PlusOutlined style={{color:'green'}}  onClick={()=>{
              this.setState({
              deletelist: [...this.state.deletelist, text]
              });

            }}/>
            </Popover>
            :null
          }

          </p>,
        }
  
      ];


      var loanrepaymentscolumns = [
        {
          title: 'AccountNumber',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'AccountName',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),
        },

        {
          title: 'Time Stamp (Actual)',
          dataIndex: 'time_stamp',
          key: 'id',
          ...this.getColumnSearchProps('time_stamp'),
        },
        {
          title: 'Date (Shedule date)',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Loan Issue date',
          dataIndex: 'loanissuedate',
          key: 'id',
          ...this.getColumnSearchProps('loanissuedate'),
        },

        {
          title: 'Loan Amount',
          dataIndex: 'loanissueamount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },

        {
          title: 'Principle Repaid',
          dataIndex: 'principle_paid',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Interest Repaid',
          dataIndex: 'interest_paid',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => {
            axios.delete(`${serverconfig.backendserverurl}/api/loanrepayments/${text}`).then(res =>{
              //window.location.reload(false)
              message.info("successfully deleted") 

              axios.get(serverconfig.backendserverurl+`/api/loanrepayments/?sacco=${sacco}&description=${'Loan Repayment'}`)
              .then(res => {
                  this.setState({
                    loanrepayments:res.data
                  })
              })
            }
            );
          }}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>

          &nbsp;&nbsp;&nbsp;
          {
            this.state.deletemode==="loanrepayments"?
            <Popover content={<p>Click here to add to list</p>} title="Add to Delete list">
            <PlusOutlined style={{color:'green'}}  onClick={()=>{
              this.setState({
              deletelist: [...this.state.deletelist, text]
              });

            }}/>
            </Popover>
            :null
          }


          </p>,
        }
  
      ];



      var membercolumns = [
        {
          title: 'Account Number',
          dataIndex: 'AccountNumber',
          key: 'id',
          ...this.getColumnSearchProps('AccountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'FullName',
          key: 'id',
          ...this.getColumnSearchProps('FullName'),
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          key: 'id',
          ...this.getColumnSearchProps('gender'),
        },
        {
          title: 'Account Type',
          dataIndex: 'accounttypename',
          key: 'id',
          ...this.getColumnSearchProps('accounttypename'),
        },
        {
          title: 'Account Contact',
          dataIndex: 'RegisteredPhoneNumber1',
          key: 'id',
          ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
        },
        {
          title: 'Account Status',
          dataIndex: 'account_status',
          key: 'id',
          ...this.getColumnSearchProps('account_status'),
        },
        {
          title: 'User',
          dataIndex: 'username',
          key: 'id',
          ...this.getColumnSearchProps('username'),
        },
        {
          title: 'Account Balance',
          dataIndex: 'UserBalance',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Loan Reserve',
          dataIndex: 'loan_security',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <p>
            <Popover content={<p>Click here to edit Account Balance</p>} title="Change Account Balance">
              <EditOutlined style={{color:'green'}} onClick={()=>{
                this.setState({selectedaccountobject:record})
                this.setState({accountbalance:record.UserBalance})
                this.setState({updateformvisible:true})
              }}/>
            </Popover>
          </p>,
        }
  
      ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>

    <h3>Select Mass Delete Mode</h3>

    <FormItem 
        label="Delete Mode"
        >
            <Select 
            value={this.state.deletemode} 
            onChange={(val)=>{
              this.setState({deletelist:[]})
              
              this.setState({deletemode:val})}}
            placeholder="Delete Mode" >
              <Option value='transactions'>Transactions</Option>
              <Option value='till'>Till</Option>
              <Option value='incomes'>Incomes</Option>
              <Option value='loanaplications'>Loanapplications</Option>                         
              <Option value='loanrepayments'>loanrepayments</Option>                         
            </Select>
    </FormItem>

    <h3>Delete List: {
      
      this.state.deletelist.map((item)=>{
        return String(item)+" ";

      })
      }</h3>

     <Button  type="primary" htmlType='button'
            onClick={()=>{
              this.setState({datarequested:true})

              let form_data = new FormData();
              form_data.append('deletemode',this.state.deletemode);
              form_data.append('deletelist',JSON.stringify(this.state.deletelist) );

              axios.post(serverconfig.backendserverurl+'/customqueries/massdeleting', form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
                })
              .then(res => {
                //get new data back

                axios.get(serverconfig.backendserverurl+`/api/incomes/?sacco=${sacco}`)
                .then(res => {
                    this.setState({
                      incomes:res.data
                    })
                })
            
                axios.get(serverconfig.backendserverurl+`/api/transactions/?sacco=${sacco}`)
                .then(res => {
                    this.setState({
                      transactions:res.data
                    })
                })
            
                axios.get(serverconfig.backendserverurl+`/api/till/?sacco=${sacco}`)
                .then(res => {
                    this.setState({
                      till:res.data
                    })
                })
            
                axios.get(serverconfig.backendserverurl+`/api/incomes/?sacco=${sacco}`)
                .then(res => {
                    this.setState({
                      incomes:res.data
                    })
                })
            
                axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}`)
                .then(res => {
                    this.setState({
                      loanapplications:res.data
                    })
                })
            
                axios.get(serverconfig.backendserverurl+`/api/loanrepayments/?sacco=${sacco}&description=${'Loan Repayment'}`)
                .then(res => {
                    this.setState({
                      loanrepayments:res.data
                    })
                })
            
                axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
                .then(res => {
                    this.setState({
                      members:res.data
                    })
                })

                this.setState({datarequested:false})

                this.setState({deletelist:[]})


              })
              .catch(error => console.log(error))

            }}
            
            >Batch Delete</Button>

    <br></br>
    <br></br>

    <Tabs  type="card">
      <TabPane tab="ALL TRANSACTIONS" key="1" defaultActiveKey="1">
      <Collapse defaultActiveKey={['1']} onChange={this.callback}>
      <Panel header="ALL TRANSACTIONS" key="1">
        <Table   
        columns={transactioncolumns} 
        scroll={{ x: 1000 }}
        dataSource={this.state.transactions} 
        pagination={{showQuickJumper:true,showSizeChanger:true }}
        bordered/>
    
      </Panel>
      </Collapse>        
      </TabPane>

      <TabPane tab="TILL" key="2" defaultActiveKey="1">
      <Collapse defaultActiveKey={['1']} onChange={this.callback}>
      <Panel header="TILL" key="1">
        <Table   
          columns={tillcolumns} 
          scroll={{ x: 1000 }}
          dataSource={this.state.till} 
          pagination={{showQuickJumper:true,showSizeChanger:true }}
          bordered/>
    
      </Panel>
      </Collapse>        
      </TabPane>

      <TabPane tab="Incomes" key="3" defaultActiveKey="1">
      <Collapse defaultActiveKey={['1']} onChange={this.callback}>
      <Panel header="Incomes" key="1">
        <Table   
          columns={incomecolumns} 
          scroll={{ x: 1000 }}
          dataSource={this.state.incomes} 
          pagination={{showQuickJumper:true,showSizeChanger:true }}
          bordered/>
      </Panel>
      </Collapse>        
      </TabPane>

      <TabPane tab="Loan Applications" key="4" defaultActiveKey="1">
      <Collapse defaultActiveKey={['1']} onChange={this.callback}>
      <Panel header="Loan Applications" key="1">
        <Table   
          columns={loanapplicationscolumns} 
          scroll={{ x: 1000 }}
          dataSource={this.state.loanapplications} 
          pagination={{showQuickJumper:true,showSizeChanger:true }}
          bordered/>
      </Panel>
      </Collapse>        
      </TabPane>

      <TabPane tab="Loan Repayments" key="5" defaultActiveKey="1">
      <Collapse defaultActiveKey={['1']} onChange={this.callback}>
      <Panel header="Loan Repayments" key="1">
        <Table   
          columns={loanrepaymentscolumns} 
          scroll={{ x: 1000 }}
          dataSource={this.state.loanrepayments} 
          pagination={{showQuickJumper:true,showSizeChanger:true }}
          bordered/>
      </Panel>
      </Collapse>        
      </TabPane>

      <TabPane tab="Member Balances" key="6" defaultActiveKey="1">
      <Collapse defaultActiveKey={['1']} onChange={this.callback}>
      <Panel header="Member Balances" key="1">
        <Table   
          columns={membercolumns} 
          scroll={{ x: 1000 }}
          dataSource={this.state.members} 
          pagination={{showQuickJumper:true,showSizeChanger:true }}
          bordered/>
      </Panel>
      </Collapse>        
      </TabPane>

      </Tabs>






      <Modal              
        visible={this.state.updateformvisible}
        title={"Member Account Balance Update Form"}
        onCancel={()=>{this.setState({updateformvisible:false})}}
        style={{width:1000}}
        footer={[
          <Button key="back" onClick={()=>{this.setState({updateformvisible:false})}}>
            Cancel
          </Button>
          ]}
          >

          <FormItem label='Account Balance'
          >
            <Input name="paidinby"  placeholder="Name" value={this.state.accountbalance} onChange={(val)=>{this.setState({accountbalance:val.target.value})}}/>
          </FormItem>

          <FormItem>
            <Button  type="primary" htmlType='button'
            onClick={()=>{
              let form_data = new FormData();
              form_data.append('accountid',this.state.selectedaccountobject.id);
              form_data.append('newbalance', this.state.accountbalance);

              axios.post(serverconfig.backendserverurl+'/customqueries/updateaccountbalance', form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
                })
              .then(res => {
                console.log(res.data)
                this.setState({updateformvisible:false})  

                axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
                .then(res => {
                    this.setState({
                      members:res.data
                    })
                })
              })
              .catch(error => console.log(error))

            }}
            
            >Update</Button>
          </FormItem>

      </Modal> 

      <Modal              
              visible={this.state.editmodalvisiblae}
              title={"Transaction Edit Form"}
              onCancel={()=>{this.setState({editmodalvisiblae:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({editmodalvisiblae:false})}}>
                  Cancel
                </Button>
                ]}
                >

              <FormItem 
                    label="Debit"
                    >
              <Input name="Debit"  placeholder="debit." value={this.state.debit} onChange={(val)=>{this.setState({debit:val.target.value})}} />                          
              </FormItem>

              <FormItem 
                    label="Credit"
                    >
              <Input name="Credit"  placeholder="credit." value={this.state.credit} onChange={(val)=>{this.setState({credit:val.target.value})}} />                          
              </FormItem>

              <FormItem 
                    label="Balance"
                    >
              <Input name="Balance"  placeholder="balance." value={this.state.balance} onChange={(val)=>{this.setState({balance:val.target.value})}} />                          
              </FormItem>

              <FormItem 
                    label="Trans Desc"
                    >
              <Input name="desc"  placeholder="desc." value={this.state.transdesc} onChange={(val)=>{this.setState({transdesc:val.target.value})}} />                          
              </FormItem>

              <Button  type="primary" htmlType='button'
            onClick={()=>{
              let form_data = new FormData();
              form_data.append('credit',this.state.credit);
              form_data.append('debit', this.state.debit);
              form_data.append('balance', this.state.balance);
              form_data.append('transid', this.state.selectedtranobj.id);
              form_data.append('transaction_details', this.state.transdesc);

              axios.post(serverconfig.backendserverurl+`/customqueries/edittransaction`, form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
                })
              .then(res => {
                console.log(res.data)
                this.setState({editmodalvisiblae:false})  
                window.location.reload(false)

              })
              .catch(error => console.log(error))
            }}
            
            >Update</Button>

            </Modal> 

    </div>
    )

    }

  }
}

export default TransactionList; 
