import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Avatar,Spin,Image,Modal,Input,message,Select,Checkbox,Switch } from 'antd';
import { PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import ImgsViewer from 'react-images-viewer'
import PhoneInput from 'react-phone-number-input'
import * as serverconfig from '../../views/serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;



  var sacco= ''
  var username=''
  var token= ''

class SaccoDetail extends React.Component{

    state={
        companyname:'',  
        licence:'',
        companyemail:'',
        companylogo:null,   
        companyphone:'',
        boxnumber:'',
        accountslimit:0,
        branchname:'',
        companyphonetwo:'',
        companyphonethree:'',
        website:'',
        address:'',
        datarequested:true,  
        safebalance:0,
        sharevalue:0,
        retainedearnings:0,
        profile:{},
        activated:false,
        usesms:false,
        packages:[],
        package:'',
        roundoffvalue:0,
        sharetransfercharge:0,

        showwelfare:false,
        showemergency:false,
        showdream:false,
        showlegacy:false,
        showchildren:false,
        showuniversity:false,
        showsubaccounttransfer:false,

        automatedloanrepayment:false,
        smsonautomatedloanrepayment:false,
        loanrepaymentalertdays:0,
        automatedloanreminders:false,
        dataidentificationcode:'',

        showgroupaccounts:false,
        showeditablewithdrawcharge:false,
        sharesfeature:false,
        userbalancesfeature:false,

        email_statements:false,
        attach_officer_to_loan:false,
        automated_accounts:false,

        automated_transaction_date:false,
        automated_transaction_slipnumber:false

    }


    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         username=''
      }
    
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

     //call component mount methods
     this.componentmountactions();

    }


    //component mount method
    componentmountactions=()=>{
      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
              profile: res.data
          })

          this.setState({companyname:res.data.sacco_name})
          this.setState({branchname:res.data.branchname})
          this.setState({companyemail:res.data.company_email})
          this.setState({companyphone:res.data.RegisteredPhoneNumber1})
          this.setState({boxnumber:res.data.box_number})
          this.setState({accountslimit:res.data.accountslimit})
          this.setState({companyphonetwo:res.data.RegisteredPhoneNumber2})
          this.setState({companyphonethree:res.data.RegisteredPhoneNumber3})
          this.setState({website:res.data.website})
          this.setState({activated:res.data.activated})
          this.setState({address:res.data.address})
          this.setState({safebalance:res.data.safe_balance})
          this.setState({sharevalue:res.data.sharevalue})
          this.setState({retainedearnings:res.data.retained_earnings})
          this.setState({usesms:res.data.usesms})
          this.setState({licence:res.data.licencedays})
          this.setState({package:res.data.package})
          this.setState({roundoffvalue:res.data.roundoffvalue})
          this.setState({sharetransfercharge:res.data.sharetransfercharge})

          this.setState({showwelfare:res.data.showwelfare})
          this.setState({showemergency:res.data.showemergency})
          this.setState({showdream:res.data.showdream})
          this.setState({showlegacy:res.data.showlegacy})
          this.setState({showchildren:res.data.showchildren})
          this.setState({showuniversity:res.data.showuniversity})
          this.setState({showsubaccounttransfer:res.data.showsubaccounttransfer})


          this.setState({automatedloanrepayment:res.data.automatedloanrepayment})
          this.setState({smsonautomatedloanrepayment:res.data.smsonautomatedloanrepayment})
          this.setState({loanrepaymentalertdays:res.data.loanrepaymentalertdays})
          this.setState({automatedloanreminders:res.data.automatedloanreminders})
          this.setState({dataidentificationcode:res.data.dataidentificationcode})


          this.setState({showgroupaccounts:res.data.showgroupaccounts})
          this.setState({showeditablewithdrawcharge:res.data.showeditablewithdrawcharge})
          this.setState({sharesfeature:res.data.sharesfeature})
          this.setState({userbalancesfeature:res.data.userbalancesfeature})

          this.setState({email_statements:res.data.email_statements})
          this.setState({attach_officer_to_loan:res.data.attach_officer_to_loan})
          this.setState({automated_accounts:res.data.automated_accounts})

          this.setState({automated_transaction_date:res.data.automated_transaction_date})
          this.setState({automated_transaction_slipnumber:res.data.automated_transaction_slipnumber})


      })

      axios.get(serverconfig.backendserverurl+'/api/packages/')
      .then(res => {
          this.setState({
            packages:res.data
          })
  
          this.setState({datarequested:false});
      })



    }


    handleCompanyNameChange= (e) => this.setState({ companyname: e.target.value});
    handleLicenceChange= (e) => this.setState({ licence: e.target.value});
    handleCompanyEmailChange= (e) => this.setState({ companyemail: e.target.value});
    handleCompanyLogoChange= (e) => this.setState({ companylogo: e.target.files[0]});
  
    handleCompanyPhoneChange= (e) => this.setState({ companyphone: e});
    handleCompanyBoxNumberChange= (e) => this.setState({ boxnumber: e.target.value});
    handleaccountslimitChange=(e) => this.setState({ accountslimit: e.target.value});
    handlebranchnameChange=(e) => this.setState({ branchname: e.target.value});
    handleentitytypeChange= (e) => this.setState({ entitytype: e});
    
    handleCompanyPhonetwoChange= (e) => this.setState({ companyphonetwo: e});
    handleCompanyPhonethreeChange= (e) => this.setState({ companyphonethree: e});
    handlecompanydealershipChange= (e) => this.setState({ companydealership: e.target.value});
    handlewebsiteChange= (e) => this.setState({ website: e.target.value});
      
    handleAddressChange= (e) => this.setState({ address: e.target.value});
    handleretainedearningsChange= (e) => this.setState({ retainedearnings: e.target.value});
    handlesharevalueChange= (e) => this.setState({ sharevalue: e.target.value});
    handlesafebalanceChange= (e) => this.setState({ safebalance: e.target.value});
    handleroundoffvalueChange= (e) => this.setState({ roundoffvalue: e.target.value});
    handlesharetransferchargeChange= (e) => this.setState({ sharetransfercharge: e.target.value});

    handleloanrepaymentalertdaysChange= (e) => this.setState({ loanrepaymentalertdays: e.target.value});



    //submit button pressed
    handleFormSubmit=() =>{
        const saccoId=this.props.match.params.saccoId;

        this.setState({datarequested:true})

        let form_data = new FormData();
        form_data.append('sacco_name', this.state.companyname);
        form_data.append('branchname', this.state.branchname);
        form_data.append('company_email', this.state.companyemail);
        form_data.append('RegisteredPhoneNumber1', this.state.companyphone);
        form_data.append('box_number', this.state.boxnumber);
        form_data.append('licencedays', this.state.licence);
        form_data.append('accountslimit',this.state.accountslimit);
        this.state.companylogo==null?
    
        console.log("No profile file")
        :
        form_data.append('sacco_logo', this.state.companylogo,this.state.companylogo?this.state.companylogo.name:"");
        
        form_data.append('website',this.state.website);
        form_data.append('RegisteredPhoneNumber2', this.state.companyphonetwo);
        form_data.append('RegisteredPhoneNumber3', this.state.companyphonethree);
        form_data.append('usesms', this.state.usesms);

        form_data.append('safe_balance', this.state.safebalance);
        form_data.append('sharevalue', this.state.sharevalue);
        form_data.append('retained_earnings', this.state.retainedearnings);
        form_data.append('activated', this.state.activated);
        form_data.append('address',this.state.address);
        form_data.append('package',this.state.package);
        form_data.append('roundoffvalue',this.state.roundoffvalue);

        form_data.append('showdream', this.state.showdream);
        form_data.append('showchildren', this.state.showchildren);
        form_data.append('showemergency', this.state.showemergency);
        form_data.append('showlegacy', this.state.showlegacy);
        form_data.append('showuniversity', this.state.showuniversity);
        form_data.append('showwelfare', this.state.showwelfare);
        form_data.append('showsubaccounttransfer', this.state.showsubaccounttransfer);

        form_data.append('sharetransfercharge', this.state.sharetransfercharge);

        form_data.append('automatedloanrepayment', this.state.automatedloanrepayment);
        form_data.append('smsonautomatedloanrepayment', this.state.smsonautomatedloanrepayment);
        form_data.append('loanrepaymentalertdays', this.state.loanrepaymentalertdays);
        form_data.append('automatedloanreminders', this.state.automatedloanreminders);
        form_data.append('dataidentificationcode',this.state.dataidentificationcode );

        form_data.append('showgroupaccounts', this.state.showgroupaccounts);
        form_data.append('showeditablewithdrawcharge', this.state.showeditablewithdrawcharge);
        form_data.append('sharesfeature', this.state.sharesfeature);
        form_data.append('userbalancesfeature', this.state.userbalancesfeature);

        form_data.append('email_statements', this.state.email_statements);
        form_data.append('attach_officer_to_loan', this.state.attach_officer_to_loan);
        form_data.append('automated_accounts', this.state.automated_accounts);

        form_data.append('automated_transaction_date', this.state.automated_transaction_date);
        form_data.append('automated_transaction_slipnumber', this.state.automated_transaction_slipnumber);

        axios.put(serverconfig.backendserverurl+`/api/saccos/${sacco}/`, form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info('Successfully updated sacco')
        this.componentmountactions();
      }     
      )
      .catch(error => console.log(error))

    }


    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
          }else{
            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="6">
                    <Card title={<h3 style={{fontWeight:'bolder'}}>{this.state.profile.sacco_name}</h3> }>
                        <p>Email: {this.state.profile.company_email}</p>
                        <p>Phone: {this.state.profile.RegisteredPhoneNumber1}</p>
                        <p>Phone 2: {this.state.companyphonetwo}</p>
                        <p>Website: {this.state.profile.website}</p>
                        <p>Box No: {this.state.profile.box_number}</p>
                        <p>Office Address: {this.state.profile.address}</p>
                        <p>Branch Name: {this.state.profile.branchname}</p>
                        <p>Safe Balance: {this.state.profile.safe_balance}</p>
                        <p>Retained Earnings: {this.state.profile.retained_earnings}</p>
                        <p>Round Off Value: {this.state.profile.roundoffvalue}</p>
                        <p>Share Value: {this.state.profile.sharevalue}</p>
                        <p>Share Transfer Charge: {this.state.profile.sharetransfercharge}</p>
                        <p>SMS Balance: {this.state.profile.smsmessages} Messages</p>

                    </Card>
                    </Col>
    
                    <Col xs="12" sm="6" lg="6">
                      <Card 
                            style={{padding:10,alignContent:'center',alignItems:'center'}}
                            hoverable
                            cover={
                            
                                <Image
                                width={100}
                                //height={'50%'}
                                src={this.state.profile.sacco_logo}    
                                />                   
                             }
                        >
                        </Card>


                   
                    </Col>
                   
    
                    </Row>
                    <br/>
    
                    <Row>
                    <Col xs="12" sm="6" lg="6">
                    <Card 
                            style={{padding:10}}
                            hoverable>
    
                        <h4>Profile Update</h4>
                        <Form
                            >
                                <FormItem label="Sacco Name"
                   
                                >
                                <Input name="sacconame"  placeholder="Put a name here." value={this.state.companyname} onChange={this.handleCompanyNameChange} />
                                </FormItem>
                    
                    
                                <FormItem label="Branch Name"
                        
                                >
                                <Input  placeholder="Put a name here." value={this.state.branchname} onChange={this.handlebranchnameChange} />
                                </FormItem>
                    
                                <FormItem label="Sacco Logo">
                                <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo"  onChange={this.handleCompanyLogoChange}/>
                                </FormItem>  
                                
                                <FormItem label="Sacco Email"
                 
                                >
                                <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.companyemail} onChange={this.handleCompanyEmailChange} />
                                </FormItem> 
                    
                                <FormItem label="Phone Number">
                                <PhoneInput
                                    countrySelectProps={{ unicodeFlags: true }}
                                    defaultCountry="UG"
                                    placeholder="Enter phone number"
                                    value={this.state.companyphone} onChange={this.handleCompanyPhoneChange}/>
                                </FormItem>
                                <FormItem label="Phone Number Two">
                                <PhoneInput
                                    countrySelectProps={{ unicodeFlags: true }}
                                    defaultCountry="UG"
                                    placeholder="Enter phone number"
                                    value={this.state.companyphonetwo} onChange={this.handleCompanyPhonetwoChange}/>
                                </FormItem> 
                    
                                <FormItem label="Phone Number Three">
                                <PhoneInput
                                    countrySelectProps={{ unicodeFlags: true }}
                                    defaultCountry="UG"
                                    placeholder="Enter phone number"
                                    value={this.state.companyphonethree} onChange={this.handleCompanyPhonethreeChange}/>
                                </FormItem>  
                                <FormItem label="Website">
                                <Input  name="website"  placeholder="Webiste here" value={this.state.website} onChange={this.handlewebsiteChange} />
                                </FormItem>
                    
                                <FormItem label="Box number">
                                <Input name="boxnumber"  placeholder="Box number here" value={this.state.boxnumber} onChange={this.handleCompanyBoxNumberChange} />
                                </FormItem>
                    
                                <FormItem label="Address">
                                <Input name="address"  placeholder="Address here" value={this.state.address} onChange={this.handleAddressChange} />
                                </FormItem>

                    
                                <FormItem label="Safe Balance"
                          
                                >
                                <Input name="safebalance"  placeholder="Safe Balance" value={this.state.safebalance} onChange={this.handlesafebalanceChange} />
                                </FormItem>

                                <FormItem label="Share Value"
                           
                                >
                                <Input name="sharevalue"  placeholder="Share Value" value={this.state.sharevalue} onChange={this.handlesharevalueChange} />
                                </FormItem>          

                                <FormItem label="Retained Earnings"
                             
                                >
                                <Input name="retained_earnings"  placeholder="Retained Earnings" value={this.state.retainedearnings} onChange={this.handleretainedearningsChange} />
                                </FormItem>


                                <FormItem label="Round Off Value">
                                    <Input name="roundoffvalue"  placeholder="Round Off Value" value={this.state.roundoffvalue} onChange={this.handleroundoffvalueChange} />
                                </FormItem>

                                <FormItem label="Share Transfer Charge">
                                    <Input name="sharetransfercharge"  placeholder="Share Transfer Charge" value={this.state.sharetransfercharge} onChange={this.handlesharetransferchargeChange} />
                                </FormItem>

                                <FormItem label="Loan Reminder Days">
                                    <Input name="loanrepaymentalertdays"  placeholder="Loan Reminder days" value={this.state.loanrepaymentalertdays} onChange={this.handleloanrepaymentalertdaysChange} />
                                </FormItem>
                           

                            <FormItem>
                            <Button type="primary" htmlType="button" onClick={(event) => this.handleFormSubmit()}>Update</Button>
                            </FormItem>
                            </Form>
                    </Card>
                       
    
                    </Col>
                
                    </Row>
    
                    <br/>
    
                </div>
            )

          }
     
    }
}

export default SaccoDetail; 