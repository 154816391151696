import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin,DatePicker} from 'antd';
import IDTypeForm from '../components/IDTypeForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
import moment from 'moment';

const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var token= ''

class NonWorkingDayDetail extends React.Component{

    state={
        nonworkingday: {},
        date:moment().format(dateFormat).toString(),  
        event:'',
        datarequested:true,
    }


    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        const dayID=this.props.match.params.dayID;
        axios.get(`${serverconfig.backendserverurl}/api/nonworkingdays/${dayID}`)
        .then(res => {  
            this.setState({
                nonworkingday: res.data
            })

            this.setState({date:res.data.date})
            this.setState({event:res.data.event})
            this.setState({datarequested:false})

        })
    }

    handleeventChange= (e) => this.setState({ event: e.target.value});
    handledatechange= (date, dateString) => this.setState({ date: dateString});

    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const dayID=this.props.match.params.dayID;

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('event', this.state.event);

    axios.put(`${serverconfig.backendserverurl}/api/nonworkingdays/${dayID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>this.setState({datarequested:false}),window.location.reload(false),message.info('Successfully updated'))
    .catch(error => console.log(error))


  }



    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card 
                    style={{padding:10}}
                    hoverable>
                        <h4>Day  Update</h4>
                        <Form>
                        <FormItem label={"Date: "+this.state.date}>
                            <DatePicker onChange={this.handledatechange} format={dateFormat} />
                        </FormItem>


                        <FormItem label="Event">
                            <Input name="event"  placeholder="Put event here." value={this.state.event} onChange={this.handleeventChange} />
                        </FormItem>
                        
                            <FormItem>
                            <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                            </FormItem>
                        </Form>
    
                    </Card>
    
                    </Col>
    
                    </Row>
                  <br/>
    
                </div>
            )

          }
       
    }
}

export default NonWorkingDayDetail; 