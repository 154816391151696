import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''
var sacco= ''
var bizuserid= ''


class ReportToPrint extends React.Component {

    state = {
      companyprofile:{}
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })
  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h5>{this.state.companyprofile.companydealership}</h5>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>

                  </Col>

           </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>DIVIDENDS REPORT FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

             <h4>INCOMES </h4>
            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Income Category</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.incomes.map(
                        (item)=>(
                          <tr>
                          <td>{item.incomecategory}</td>
                          <td>{<CurrencyFormat value={item.totalPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalincomes} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table>   

            <br></br> 
            <Divider style={{borderColor:'red'}} dashed />
            <h4>EXPENSES</h4>

            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Expense Category</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.expenses.map(
                        (item)=>(
                          <tr>
                          <td>{item.expensecategory}</td>
                          <td>{<CurrencyFormat value={item.totalPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalexpenses} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                        <tr>
                        <td style={{fontWeight:'bolder',fontSize:20}}>SURPLUS</td>
                        <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.props.surplus} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table> 
        </div>
      );
    }
  }



class IncomeStatementsDividendsReport extends React.Component {

  state = {
   expenses:[],
   incomes:[],
   dateone:'',
   datetwo:'',
   totalincomes:0,
   totalexpenses:0,
   surplus:0,
   datarequested:false,
   auditednetprofit:0,
   sharedmemebers:[],
   distributionmode:'',

   sharablepercentageofprofit:0,


  };

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

  }

  handledatechange= (date, dateString) =>{
    this.setState({ dateone: dateString[0]});
    this.setState({ datetwo: dateString[1]});
  } 

  //search incomes method
  getstatement=()=>{
   let form_data = new FormData();
   form_data.append('dateone', this.state.dateone);
   form_data.append('datetwo', this.state.datetwo);
   form_data.append('sacco', sacco);
   
   if(this.state.dateone===''||this.state.datetwo===''){
    alert("Please are dates missing")
   }else{
    this.setState({datarequested:true})

       //Now submit sale data to database
       axios.post(serverconfig.backendserverurl+'/customqueries/getincomestatementdividends', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
         })
         .then(res =>{
             this.setState({datarequested:false})
             this.setState({sharedmemebers:JSON.parse(res.data.sharedmemebers)})
         } 
         )
         .catch(error => console.log(error)) 
   }

   }

   //calcluate the specific user received percentage
   calculatememberperctages=(sharevalue)=>{
     var newarray=[]

     this.state.sharedmemebers.map((item)=>{

      var newitem={
        'id':item.id,
        'accountnumber':item.accountnumber,
        'accountname':item.accountname,
        'sharepercentage':item.sharepercentage,
        'dividendvalue':(Number(item.sharepercentage)/100)*sharevalue
      }

      newarray.push(newitem)

     })

     this.setState({sharedmemebers:newarray})

   }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
      
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <div style={{display: "none"}}>
             &nbsp;&nbsp;
            <ReportToPrint
            totalincomes={this.state.totalincomes} 
            totalexpenses={this.state.totalexpenses} 
            surplus={this.state.surplus} 
            dateone={this.state.dateone}
            datetwo={this.state.datetwo}
            incomes={this.state.incomes}
            expenses={this.state.expenses}
            ref={el => (this.componentRef = el)} /> 

          </div>

          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="DIVIDENDS CALCULATOR" key="1">
            <Form  layout="inline" >
                <FormItem label="Date Range (Financial Year)">
                    <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                </FormItem>

                <FormItem>
                <Button onClick={this.getstatement} type="primary" htmlType="button">Search</Button>
                </FormItem> 

           </Form>

          <FormItem label="Audited Net Profit"      
          >

              <InputNumber
                style={{ width: 300 }} 
                defaultValue={0.0}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\x\s?|(,*)/g, '')}
                onChange={(val)=>{this.setState({auditednetprofit:val})}}
              />

          </FormItem>

          <FormItem label="Sharable Percentage Of Profit"      
          >
            <Input name="" placeholder="0.00" value={this.state.sharablepercentageofprofit}
            
            onChange={(val)=>{
              this.setState({sharablepercentageofprofit:val.target.value})
              this.calculatememberperctages((val.target.value/100)*this.state.auditednetprofit)
          
          }} />
          </FormItem>

          <h3>Net Sharable: {(this.state.sharablepercentageofprofit/100)*this.state.auditednetprofit}</h3>

          
            <Divider style={{borderColor:'red'}} dashed />
            <h4> MEMBER SHARABLE PERCENTAGES</h4>
                <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Account Number</th>
                          <th>Account Name</th>
                          <th>Share Percentage</th>
                          <th>Dividend Value</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.sharedmemebers.map(
                        (item)=>(
                          <tr>
                          <td>{item.accountnumber}</td>
                          <td>{item.accountname}</td>
                          <td>{item.sharepercentage}</td>
                          <td>
                          <CurrencyFormat value={item.dividendvalue} displayType={'text'} thousandSeparator={true}/>
                          </td>
                          </tr>
                        ))}
              
                    </tbody>
                </reactstrp.Table> 

          <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>

        <reactstrp.Table bordered>
              <thead>
                <tr>

                  <th>
                  <FormItem 
                    label="Dividends Distribution Mode"
                    name="divisionmode"
                    rules={[
                      {
                        required: true,
                        message: 'Please select distribution mode',
                      },
                    ]}
                    >
                        <Select 
                        value={this.state.distributionmode} 
                        onChange={(val)=>{this.setState({distributionmode:val})}}
                        placeholder="Dividend Distribution Mode" >
                              <Option value='deposittoaccounts'>Deposit to Accounts</Option>
                              <Option value='deposittoshares'>Deposit to Shares</Option>
                        </Select>
                    </FormItem>

                    <FormItem>
                    <Button onClick={()=>{

                      let form_data = new FormData();
                      form_data.append('distributionmode', this.state.distributionmode);
                      form_data.append('sacco', sacco);
                      form_data.append('sharedmemebers',JSON.stringify(this.state.sharedmemebers));
                      form_data.append('date', moment().format(dateFormat).toString());

                      this.setState({datarequested:true})
                      //Now submit sale data to database
                      axios.post(serverconfig.backendserverurl+'/customqueries/dividenddistribution', form_data,{
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                        })
                        .then(res =>{
                            this.setState({datarequested:false})
                            window.location.reload(false)
                        } 
                        )
                        .catch(error => console.log(error)) 

                    }} type="primary" htmlType='button'>Distribute Dividends</Button>
                     </FormItem>

                  </th>

                </tr>
              </thead>
              <tbody>

              </tbody>
          </reactstrp.Table> 
          </div>

          </Panel>
          </Collapse>
        </div>

    )

    }

  }
}

export default IncomeStatementsDividendsReport; 
