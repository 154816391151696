import React from 'react';
import { Form, Input, Button,Select,Spin,Switch,Card, message,Result } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;

var sacco= ''
var username=''
var token= ''

class LoanAssessmentForm extends React.Component {

  state = {
    loanapplications:[],
    date:moment().format(dateFormat).toString(),
    applicant_location:false,
    own_premises:false,
    legal_status_of_business:false,
    duration_in_business:false,
    monthly_net_profit:false,
    guarantors:false,
    consents:false,
    report_details:'',
    loan_checklist:null,
    collateral_one:'',
    collateral_one_value:0,
    collateral_one_image:null,
    datarequested:true,
    loanapplicationobject:{},
    datasubmittedsuccessfully:false,

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    const loanapplicationID=this.props.match.params.loanapplicationID;

    axios.get(`${serverconfig.backendserverurl}/api/loanapplications/${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapplicationobject: res.data})
        this.setState({datarequested:false})
    })

  }

  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    const loanapplicationID=this.props.match.params.loanapplicationID;
    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('loan_assessed', loanapplicationID);
    form_data.append('applicant_location', this.state.applicant_location);
    form_data.append('own_premises', this.state.own_premises);
    form_data.append('legal_status_of_business', this.state.legal_status_of_business);
    form_data.append('duration_in_business', this.state.duration_in_business);
    form_data.append('monthly_net_profit', this.state.monthly_net_profit);
    form_data.append('guarantors', this.state.guarantors);
    form_data.append('consents', this.state.consents);
    form_data.append('report_details', this.state.report_details);
    form_data.append('collateral_one', this.state.collateral_one);
    form_data.append('collateral_one_value', this.state.collateral_one_value);
    form_data.append('user', username);

    form_data.append('sacco', sacco);
    
    this.state.loan_checklist==null?
    console.log("No checklist file")
    :
    form_data.append('loan_checklist', this.state.loan_checklist,this.state.loan_checklist?this.state.loan_checklist.name:"");

    this.state.collateral_one_image==null?
    console.log("No collateral file")
    :
    form_data.append('collateral_one_image', this.state.collateral_one_image,this.state.collateral_one_image?this.state.collateral_one_image.name:"");


    axios.post(serverconfig.backendserverurl+'/api/loanassessments/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>  {
    //console.log(res.data)
    var obj=res.data
    console.log(Object.keys(obj).length)
    if(Object.keys(obj).length>0){
        //get last slip number
        let form_data = new FormData();
          form_data.append('looanid', loanapplicationID);
          axios.post(serverconfig.backendserverurl+'/customqueries/updateloanassessmentstatus', form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
            })
          .then(res => {
              message.info(res.data.message)
              this.setState({datarequested:false})
              this.setState({datasubmittedsuccessfully:true})
          })
          .catch(error => console.log(error))

    }else{
      message.info("Some thing is wrong")
    }

  }
  )
  .catch(error => console.log(error))

  }

  handleapplicant_locationChange= (e) =>{
    console.log(e)
    this.setState({ applicant_location: e});
  } 
  handleown_premisesChange= (e) => this.setState({ own_premises: e});
  handlelegal_status_of_businessChange= (e) => this.setState({ legal_status_of_business: e});

  handleduration_in_businessChange= (e) => this.setState({ duration_in_business: e});
  handlemonthly_net_profitChange= (e) => this.setState({ monthly_net_profit: e});
  handleguarantorsChange= (e) => this.setState({ guarantors: e});
  handleconsentsChange= (e) => this.setState({ consents: e});

  handlereport_detailsChange= (e) => this.setState({ report_details: e.target.value});
  handlecollateral_oneChange= (e) => this.setState({ collateral_one: e.target.value});
  handlecollateral_one_valueChange= (e) => this.setState({ collateral_one_value: e.target.value});

  handleloan_checklistChange= (e) => this.setState({ loan_checklist: e.target.files[0]});
  handlecollateral_one_imageChange= (e) => this.setState({ collateral_one_image: e.target.files[0]});



  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{


      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <Result
            status="success"
            title="Successfully Assessed Loan "
            subTitle="Application will be ready for approval"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />

            </Card>
            </Col>
            </Row>
        );

      }else{

        return (
          <div>
              <Row>
              <Col xs="12" sm="6" lg="6">
  
              <Card 
                style={{padding:10}}
                hoverable>
                <h3 style={{color:'green'}}>Loan Assessment Form</h3>
                <h4>Account No: {this.state.loanapplicationobject.accountNumber}</h4>
                <h4>Account Name: {this.state.loanapplicationobject.accountName}</h4>
                <h4>Loan Amount:<CurrencyFormat value={this.state.loanapplicationobject.loan_amount} displayType={'text'} thousandSeparator={true}  /> UGX</h4>
  
              {
                this.state.loanapplicationobject.assessed===true?
                null
                :
                <Form
                  onFinish={(event) => this.handleFormSubmit(event)} 
                >
  
                <FormItem label="Applicant Location" >
                      <Switch
                          checked={this.state.applicant_location}
                          onChange={val=>{this.handleapplicant_locationChange(val)}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                </FormItem>
  
                <FormItem label="Own Premises" >
                      <Switch
                          checked={this.state.own_premises}
                          onChange={val=>{this.handleown_premisesChange(val)}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                </FormItem>
  
                <FormItem label="Legal Status Of Business" >
                      <Switch
                          checked={this.state.legal_status_of_business}
                          onChange={val=>{this.handlelegal_status_of_businessChange(val)}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                </FormItem>
          
                <FormItem label="Duration in Business" >
                      <Switch
                          checked={this.state.duration_in_business}
                          onChange={val=>{this.handleduration_in_businessChange(val)}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                </FormItem>
  
                <FormItem label="Monthly Net Profit Of Business" >
                      <Switch
                          checked={this.state.monthly_net_profit}
                          onChange={val=>{this.handlemonthly_net_profitChange(val)}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                </FormItem>
  
                <FormItem label="Guarantors" >
                      <Switch
                          checked={this.state.guarantors}
                          onChange={val=>{this.handleguarantorsChange(val)}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                </FormItem>
  
                <FormItem label="Consents" >
                      <Switch
                          checked={this.state.consents}
                          onChange={val=>{this.handleconsentsChange(val)}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                </FormItem>
  
                <FormItem label="Collateral"
                name="collateral_one"
                rules={[
                  {
                    required: true,
                    message: 'Please enter collateral',
                  },
                ]}            
              >
                <Input name="collateral_one"  placeholder="Details." value={this.state.collateral_one} onChange={this.handlecollateral_oneChange} />
              </FormItem>
  
              <FormItem label="Collateral Value"
                name="collateral_one_value"
                rules={[
                  {
                    required: true,
                    message: 'Please enter details',
                  },
                ]}            
              >
                <Input name="collateral_one_value" type='number'  placeholder="Collateral Value." value={this.state.collateral_one_value} onChange={this.handlecollateral_one_valueChange} />
              </FormItem>
  
              <FormItem label="Collateral Photo">
                <Input name="collateralphoto" type="file" accept="image/png, image/jpeg" placeholder="Collateral Photo" onChange={this.handlecollateral_one_imageChange} />
              </FormItem>
    
              <FormItem label="Report Details"
                name="report_details"
                rules={[
                  {
                    required: true,
                    message: 'Please enter details',
                  },
                ]}            
              >
                <TextArea
                  value={this.state.report_details}
                  onChange={this.handlereport_detailsChange}
                  placeholder="Report Details"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
             
              </FormItem>
  
              <FormItem label="Loan Check List File">
                <Input name="loanchecklist" type="file" accept="*" placeholder="Loan Check List" onChange={this.handleloan_checklistChange} />
              </FormItem>
             
              <FormItem>
                <Button  type="primary" htmlType="submit">Assess</Button>
              </FormItem>
            </Form>
  
            }
                
  
          </Card>
          </Col>
          
          </Row>
  
          </div>
        );



      }






      

    }


    
  }

}


export default LoanAssessmentForm;



