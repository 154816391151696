import React from 'react';
import { Form, Input, Button,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;



var token= ''
var sacco= ''


class ResetTransactionsForm extends React.Component {

  state = {
    idtypeName:'' ,  
    idtypeDescription:'',
    datarequested:false,
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('sacco', sacco);

    axios.post(serverconfig.backendserverurl+'/customqueries/resettransactions', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>
     window.location.reload(false)
  )
  .catch(error => console.log(error))

  }

  handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >


      
     
      <FormItem>
        <Button  type="primary" htmlType="submit">Submit</Button>
      </FormItem>
    </Form>
        </div>
      );

    }

   
  }

}


export default ResetTransactionsForm;



