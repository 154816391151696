import React from 'react';
import { Form, Input, Button,Select,Spin,Card,Result } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var token= ''
var sacco= ''


class GuarantorConsentForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    name:'' ,  
    gender:'',
    loanattachedto:'',
    RegisteredPhoneNumber1:'',
    nationality:'',
    idtype:'',
    idnumber:'',
    phsicaladdress:'',
    datarequested:true,
    action:'',
    idtypes:[],
    selectedloan:'',
    datasubmittedsuccessfully:false,
    
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({action:this.props.action})
    this.setState({selectedloan:this.props.loanid})

    axios.get(serverconfig.backendserverurl+`/api/idtypes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          idtypes:res.data
        })

        this.setState({datarequested:false})


    })
}


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    if (this.state.action==="addguarantor"){

      let form_data = new FormData();
      form_data.append('date', this.state.date);
      form_data.append('guarantor_name', this.state.name);
      form_data.append('loan_attached_to', this.state.selectedloan);
      form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
      form_data.append('nationality', this.state.nationality);
      form_data.append('idtype', this.state.idtype);
      form_data.append('ID_number', this.state.idnumber);
      form_data.append('physical_address', this.state.phsicaladdress);
      form_data.append('sacco', sacco);
      form_data.append('gender', this.state.gender);

      axios.post(serverconfig.backendserverurl+'/api/loanguarantors/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>{
      this.setState({datarequested:false})
      this.setState({datasubmittedsuccessfully:true})

    }         
    )
    .catch(error => console.log(error))


    }else if (this.state.action==="addconsent"){

      let form_data = new FormData();
      form_data.append('date', this.state.date);
      form_data.append('consent_name', this.state.name);
      form_data.append('loan_attached_to', this.state.selectedloan);
      form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
      form_data.append('nationality', this.state.nationality);
      form_data.append('idtype', this.state.idtype);
      form_data.append('ID_number', this.state.idnumber);
      form_data.append('physical_address', this.state.phsicaladdress);
      form_data.append('sacco', sacco);
      form_data.append('gender', this.state.gender);

      axios.post(serverconfig.backendserverurl+'/api/loanconsents/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>{
      this.setState({datarequested:false})
      this.setState({datasubmittedsuccessfully:true})

    }
    )
    .catch(error => console.log(error))

    }
   

  }

  handlenameChange= (e) => this.setState({ name: e.target.value});
  handlegenderChange= (e) => this.setState({ gender: e});
  handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});
  handlenationalityChange= (e) => this.setState({ nationality: e.target.value});
  handleidtypeChange= (e) => this.setState({ idtype: e});
  handleidnumberChange= (e) => this.setState({ idnumber: e.target.value});
  handlephsicaladdressChange= (e) => this.setState({ phsicaladdress: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){


            if(this.state.action==="addguarantor"){
              return(
                <Card>
                <Result
                status="success"
                title="Successfully Created Guarantor"
                subTitle="Guarantors guarantee loan disburshments in institutions"
                extra={[
                  <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                ]}
                />
                </Card>
            );
            }else{

              return(
                <Card>
                <Result
                status="success"
                title="Successfully Created Consent "
                subTitle="Consents guarantee loan disburshments in institutions"
                extra={[
                  <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                ]}
                />
                </Card>
            );
            }



      }else{

        return (
          <div>
            <Form
              onFinish={(event) => this.handleFormSubmit(event)} 
            >
              <FormItem label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter name',
                  },
                ]}             
              >
                <Input name="name"  placeholder="Put a name here." value={this.state.name} onChange={this.handlenameChange} />
              </FormItem>
  
              <FormItem label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: 'Please select gender',
                  },
                ]}           
              
              >
                  <Select placeholder="Gender" style={{ width: 120 }} value={this.state.gender} onChange={this.handlegenderChange} >
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                  </Select>
              </FormItem>
  
  
              <FormItem label="Phone"                    
                >
              <PhoneInput
                    countrySelectProps={{ unicodeFlags: true }}
                    defaultCountry="UG"
                    placeholder="Enter phone number"
                    value={this.state.RegisteredPhoneNumber1} onChange={this.handleRegisteredPhoneNumber1Change}/>
                </FormItem> 
  
                <FormItem label="Nationality"
                      name="nationality"
                      rules={[
                        {
                          required: true,
                          message: 'Please input nationality',
                        },
                      ]}            
                  >
                    <Input  placeholder="Nationality." value={this.state.nationality} onChange={this.handlenationalityChange} />
                  </FormItem>
    
  
                <FormItem label="ID Type"
                      name="idtype"
                      rules={[
                        {
                          required: true,
                          message: 'Please select id type',
                        },
                      ]}           
                  >
                      <Select placeholder="Id Type" style={{  }} value={this.state.ID_type} onChange={this.handleID_typeChange} >
                        {this.state.idtypes.map(
                          (type)=>(
                            <Option value={type.id}>{type.id_type_name}</Option>
                          ))}
                      </Select>
                  </FormItem>
  
                  <FormItem label="ID Number">
                      <Input  placeholder="ID Number." value={this.state.idnumber} onChange={this.handleidnumberChange} />
                  </FormItem>
  
                  <FormItem label="Physical Address">
                      <Input  placeholder="Physical Address." value={this.state.phsicaladdress} onChange={this.handlephsicaladdressChange} />
                  </FormItem>
  
              <FormItem>
                <Button  type="primary" htmlType="submit">Create</Button>
              </FormItem>
            </Form>
          </div>
        );

      }

    }

  }

}


export default GuarantorConsentForm;



