import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});



var sacco= ''
var username=''
var token= ''

class SubAccountTransferView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    accountfrom:'',
    accountfromobject:{},
    members:[],
    datarequested:true,
    datasubmittedsuccessfully:false,
    transferedamount:0,
    companyprofile:{},
    subaccount:''
  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

        this.setState({datarequested:false})

    })


  }

  

  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleaccountfromChange= (e) =>{
    this.setState({ accountfrom: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({accountfromobject: res.data})
      })
  }


  handletransferedamountChange= (e) => this.setState({ transferedamount: e});
  handlesubaccountChange= (e) => this.setState({ subaccount: e});


  //submit the deposit now
  handleFormSubmit=(event) =>{

      this.setState({datarequested:true})
      console.log(username)
  
      let form_data = new FormData();
      form_data.append('date',moment().format(dateFormat).toString());
      form_data.append('accountfrom', this.state.accountfrom);
      form_data.append('subaccount', this.state.subaccount);
      form_data.append('transferedamount', this.state.transferedamount);
      form_data.append('sacco', sacco);
      form_data.append('username', username);
  
      axios.post(serverconfig.backendserverurl+'/customqueries/subaccountTransfer', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
        
      })
      .catch(error => console.log(error))
    
    

  }



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>

            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully transfer "
                    subTitle="Successfull sub account transfer"
                    extra={[
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />
                    </Card>
                    </Col>

            </Row>


          </div>

        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Sub Account Transfer Form</h4>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)}
                    >
                    <h6 style={{color:'red'}}>NOTE: Transfer amount from other sub accounts to Savings sub account for withdraw</h6>

                    <FormItem label="Account">
                          <Select 
                          placeholder="Account No | Name" 
                          style={{  }} 
                          value={this.state.accountfrom}
                          onChange={this.handleaccountfromChange} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}
                                              
                          >
                            {this.state.members.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                              ))}
                          </Select>
                      </FormItem>
                      
                        <FormItem 
                        label="Sub Account From"
                        name="subaccount"
                        rules={[
                          {
                            required: true,
                            message: 'Please select sub account',
                          },
                        ]}
                        >
                            <Select 
                            value={this.state.subaccount} 
                            onChange={this.handlesubaccountChange}
                            placeholder="Sub Account" >

                            {
                              this.state.companyprofile.showwelfare?
                              <Option value='welfare'>WELFARE</Option>
                              :
                              null

                            }

                            {
                              this.state.companyprofile.showemergency?
                              <Option value='emergency'>EMERGENCY</Option>
                              :
                              null

                            }

                            {
                              this.state.companyprofile.showdream?
                              <Option value='dream'>DREAM</Option>    
                              :
                              null

                            }

                            {
                              this.state.companyprofile.showlegacy?
                              <Option value='legacy'>LEGACY</Option>                         
                              :
                              null

                            }

                            {
                              this.state.companyprofile.showchildren?
                              <Option value='children'>CHILDREN</Option>                         
                              :
                              null

                            }

                            {
                              this.state.companyprofile.showuniversity?
                              <Option value='university'>UNIVERSITY</Option>       
                              :
                              null
                            }

                            </Select>
                      </FormItem>


                      <FormItem label={<p style={{fontWeight:'bold'}}>Transfer Amount:</p>} >
                     
                      <InputNumber
                        style={{ width: 200 }} 
                        defaultValue={0.0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\x\s?|(,*)/g, '')}
                        onChange={this.handletransferedamountChange}
                      /> 
                     
                      </FormItem>
                      
             
                    <FormItem>
                      <Button  type="primary" htmlType="submit">Transfer</Button>
                    </FormItem>
                    </Form>
  
                    </Card>
                   
                  </Col>
  
                 <Col xs="12" sm="6" lg="6">
                   <Card>
                   <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                   <h4>Account Number:{this.state.accountfromobject.AccountNumber}</h4>
                   <h4>Account Contact:{this.state.accountfromobject.RegisteredPhoneNumber1}</h4>
                   <h4>Account Name:{this.state.accountfromobject.FullName}</h4>
                   <h4 style={{fontWeight:'bolder'}}>Savings Balance:  <CurrencyFormat value={this.state.accountfromobject.UserBalance} displayType={'text'} thousandSeparator={true}  /></h4>
                   <h4 style={{fontWeight:'bolder'}}>Shares Balance:  {this.state.accountfromobject.shares}</h4>
                   <h4 style={{fontWeight:'bolder'}}>Welfare Balance:  <CurrencyFormat displayType={'text'} value={this.state.accountfromobject.welfare_balance} thousandSeparator={true}  /></h4>
                   <h4 style={{fontWeight:'bolder'}}>Emergency Balance:  <CurrencyFormat displayType={'text'} value={this.state.accountfromobject.emergency_balance} thousandSeparator={true}  /></h4>
                   <h4 style={{fontWeight:'bolder'}}>Dream Balance:  <CurrencyFormat displayType={'text'} value={this.state.accountfromobject.dream_balance} thousandSeparator={true}  /></h4>
                   <h4 style={{fontWeight:'bolder'}}>Legacy Balance:  <CurrencyFormat displayType={'text'} value={this.state.accountfromobject.legacy_balance} thousandSeparator={true}  /></h4>
                   <h4 style={{fontWeight:'bolder'}}>Children Balance:  <CurrencyFormat displayType={'text'} value={this.state.accountfromobject.children_balance} thousandSeparator={true}  /></h4>
                   <h4 style={{fontWeight:'bolder'}}>University Balance:  <CurrencyFormat displayType={'text'} value={this.state.accountfromobject.university_balance} thousandSeparator={true}  /></h4>

                   </Card>

                </Col>
            </Row>
  
            </div>
  
       </div>              
                      
        )
      }


    }
   
  }
}

export default SubAccountTransferView; 
