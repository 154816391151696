import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin} from 'antd';
import IDTypeForm from '../components/IDTypeForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class ExternalLoanDetail extends React.Component{

    state={
        loan: {},
        institution_name:'',  
        loanbalance:'',
        datarequested:true,
    }


    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }      

        const loanID=this.props.match.params.loanID;
        axios.get(`${serverconfig.backendserverurl}/api/externalloans/${loanID}`)
        .then(res => {  
            this.setState({
                loan: res.data
            })

            this.setState({institution_name:res.data.institution_name})
            this.setState({loanbalance:res.data.loanbalance})
            this.setState({datarequested:false})

        })
    }

    handleinstitution_nameChange= (e) => this.setState({ institution_name: e.target.value});
    handleloanbalanceChange= (e) => this.setState({ loanbalance: e.target.value});
  

    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const loanID=this.props.match.params.loanID;

    let form_data = new FormData();
    form_data.append('institution_name', this.state.institution_name);
    form_data.append('loanbalance', this.state.loanbalance);

    axios.put(`${serverconfig.backendserverurl}/api/externalloans/${loanID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>this.setState({datarequested:false}),window.location.reload(false),message.info('Successfully updated'))
    .catch(error => console.log(error))

  }

    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card title={this.state.loan.institution_name}>
                        <p>Desc: {this.state.loan.loanbalance}</p>
                    </Card>
                    </Col>
                    <Col span={2}>
                 
                    </Col>
                    <Col xs="12" sm="6" lg="4">
                   
                    </Col>
    
                    </Row>
                    <br/>
    
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card 
                    style={{padding:10}}
                    hoverable>
                        <h4>External Loan Update</h4>
                        <Form>
                        <FormItem label="Institution Name">
                            <Input name="institution_name"  placeholder="Put a name here." value={this.state.institution_name} onChange={this.handleinstitution_nameChange} />
                        </FormItem>

                        <FormItem label="Loan Balance">
                            <Input name="loanbalance" type='number'  placeholder="0.00" value={this.state.loanbalance} onChange={this.handleloanbalanceChange} />
                        </FormItem>
                            <FormItem>
                            <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                            </FormItem>
                        </Form>
    
                    </Card>
    
                    </Col>
    
                    </Row>
    
                    <br/>
    
                </div>
            )


          }

       
    }
}

export default ExternalLoanDetail; 